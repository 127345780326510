<template>
  <base-item-group
    :item="item"
    :disabled="disabled"
    group
    :main-menu="mainMenu"
  />
</template>

<script>
  export default {
    name: 'ItemSubGroup',

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      mainMenu: {
        type: String,
        default: 'arago',
      },
    },
  }
</script>
