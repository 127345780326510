export default {
  namespaced: true,
  state: {
    menus: [
      {
        moduleTitle: undefined,
        moduleTitleMini: undefined,
        items: [
          {
            icon: 'mdi-home',
            title: 'Accueil',
            to: '/',
          },
          {
            icon: 'mdi-progress-upload',
            title: 'Intégration',
            to: '/integration',
            rolenameDependent: true,
          },
        ],
      },
      {
        moduleTitle: 'TRANSPARISATION',
        moduleTitleMini: 'TRANSPA',
        items: [
          {
            icon: 'mdi-clipboard-list',
            title: 'Portefeuille/Simulation',
            to: '/transparisation/portfolio',
            dataDependent: true,
          },
          {
            icon: 'mdi-view-dashboard',
            title: 'Indicateurs Transparisés',
            to: '/transparisation/reporting',
            dataDependent: true,
            simulationTranspaDependent: true,
          },
          {
            group: '/transparisation/archives',
            icon: 'mdi-archive',
            title: 'Analyse',
            rolenameDependent: true,
            children: [
              {
                title: 'Rapports Détaillés',
                to: 'details',
                rolenameDependent: true,
                archiveTranspaDependent: true,
              },
              {
                title: 'Analyse Historique',
                to: 'historical',
                rolenameDependent: true,
                archiveTranspaDependent: true,
              },
              {
                title: 'Etude Comparative',
                to: 'comparison',
                rolenameDependent: true,
                archiveTranspaDependent: true,
              },
            ],
          },

        ],
      },
      {
        moduleTitle: 'PARAMETRES',
        moduleTitleMini: 'PARAM',
        items: [
          {
            group: '',
            icon: 'mdi-cable-data',
            title: 'Solvabilité II',
            dataDependent: true,
            simulationTranspaDependent: true,
            needSolvencyRights: true,
            children: [
              {
                title: 'Paramètres SCR',
                to: 'solvency/home',
                dataDependent: true,
                simulationTranspaDependent: true,
              },
              {
                group: '',
                title: 'Données Client',
                dataDependent: true,
                simulationTranspaDependent: true,
                children: [
                  {
                    title: 'Participations Stratégiques',
                    to: 'solvency/clientdata/strategicparticipations',
                    dataDependent: true,
                    simulationTranspaDependent: true,
                  },
                  {
                    title: 'LTEI',
                    to: 'solvency/clientdata/ltei',
                    dataDependent: true,
                    simulationTranspaDependent: true,
                  },
                  {
                    title: 'Intra Groupe',
                    to: 'solvency/clientdata/intragroup',
                    dataDependent: true,
                    simulationTranspaDependent: true,
                  },
                  {
                    title: 'Exclusion SCR Concentration',
                    to: 'solvency/clientdata/concentrationexclusion',
                    dataDependent: true,
                    simulationTranspaDependent: true,
                  },
                  {
                    title: 'Taux Endettement',
                    to: 'solvency/clientdata/propertyleverage',
                    dataDependent: true,
                    simulationTranspaDependent: true,
                  },
                ],
              },
            ],
          },
          {
            group: '',
            icon: 'mdi-cable-data',
            title: 'ESG',
            dataDependent: true,
            simulationTranspaDependent: true,
            needEsgRights: true,
            children: [
              {
                title: 'Paramètres ESG',
                to: 'esg/parameters',
                dataDependent: true,
                simulationTranspaDependent: true,
              },
              {
                title: 'Notations Forcées',
                to: 'esg/noteexceptions',
                dataDependent: true,
                simulationTranspaDependent: true,
              },
            ],
          },
          {
            group: '',
            icon: 'mdi-cable-data',
            title: 'Risque',
            dataDependent: true,
            simulationTranspaDependent: true,
            needRiskRights: true,
            children: [
              {
                title: 'Scénarios Liquidité',
                to: 'risk/parameters',
                dataDependent: true,
                simulationTranspaDependent: true,
              },
            ],
          },

        ],
      },
      {
        moduleTitle: undefined,
        moduleTitleMini: undefined,
        items: [
          {
            icon: 'mdi-reload',
            title: 'Calculs',
            to: '/compute',
            dataDependent: true,
            simulationTranspaDependent: true,
            needComputingRights: true,
          },
          {
            title: 'Gestion des Sauvegardes',
            icon: 'mdi-archive',
            to: '/archives',
            rolenameDependent: true,
            needSavingRights: true,
          },
        ],
      },
      {
        moduleTitle: 'SOLVABILITE II',
        moduleTitleMini: 'S II',
        needSolvencyRights: true,
        items: [
          {
            icon: 'mdi-chart-donut-variant',
            title: 'Résultats',
            to: '/solvency/results',
            dataDependent: true,
            scrDependent: true,
            scrChangingDependent: true,
            simulationTranspaDependent: true,
          },
          {
            group: '/solvency/archives',
            icon: 'mdi-archive',
            title: 'Analyse',
            rolenameDependent: true,
            children: [
              {
                title: 'Rapports Détaillés',
                to: 'details',
                rolenameDependent: true,
                archiveSCRDependent: true,
              },
              {
                title: 'Analyse Historique',
                to: 'historical',
                rolenameDependent: true,
                archiveSCRDependent: true,
              },
              {
                title: 'Etude Comparative',
                to: 'comparison',
                rolenameDependent: true,
                archiveSCRDependent: true,
              },
            ],
          },

        ],
      },
      {
        moduleTitle: 'ESG',
        moduleTitleMini: 'ESG',
        needEsgRights: true,
        items: [
          {
            icon: 'mdi-chart-donut-variant',
            title: 'Résultats',
            to: '/esg/results',
            dataDependent: true,
            esgDependent: true,
            esgChangingDependent: true,
            simulationTranspaDependent: true,
          },
          {
            group: '/esg/archives',
            icon: 'mdi-archive',
            title: 'Analyse',
            rolenameDependent: true,
            children: [
              {
                title: 'Rapports Détaillés',
                to: 'details',
                rolenameDependent: true,
                archiveESGDependent: true,
              },
              {
                title: 'Analyse Historique',
                to: 'historical',
                rolenameDependent: true,
                archiveESGDependent: true,
              },
              {
                title: 'Etude Comparative',
                to: 'comparison',
                rolenameDependent: true,
                archiveESGDependent: true,
              },
              {
                title: 'Température Implicite',
                to: 'temperature',
                rolenameDependent: true,
                archiveESGDependent: true,
              },
            ],
          },

        ],
      },
      {
        moduleTitle: 'RISQUE',
        moduleTitleMini: 'RISQUE',
        needRiskRights: true,
        items: [
          {
            icon: 'mdi-chart-donut-variant',
            title: 'Résultats',
            to: '/risk/results',
            dataDependent: true,
            riskDependent: true,
            riskChangingDependent: true,
            simulationTranspaDependent: true,
          },
          {
            group: '/risk/archives',
            icon: 'mdi-archive',
            title: 'Analyse',
            rolenameDependent: true,
            children: [
              {
                title: 'Rapports Détaillés',
                to: 'details',
                rolenameDependent: true,
                archiveRiskDependent: true,
              },
            ],
          },

        ],
      },

      {
        moduleTitle: undefined,
        items: [
          {
            icon: 'mdi-help-circle',
            title: 'Aide',
            to: '/help',
          },
        ],
      },
    ],
  },
  getters: {
    menus: state => {
      return state.menus
    },
    augmentedMenus: (state, getters, rootState, rootGetters) => {
      var m = []
      for (var i = 0; i < getters.menus.length; i++) {
        var menu = JSON.parse(JSON.stringify(state.menus[i]))
        if (rootGetters.needSolvencyRights) {
          if (rootGetters.hasSolvencyRights) {
            m.push(menu)
          }
          continue
        }
        if (menu.needEsgRights) {
          if (rootGetters.hasEsgRights) {
            m.push(menu)
          }
          continue
        }
        if (menu.needRiskRights) {
          if (rootGetters.hasRiskRights) {
            m.push(menu)
          }
          continue
        }
        if (menu.needSavingRights) {
          if (rootGetters.hasSavingRights) {
            m.push(menu)
          }
          continue
        }
        if (menu.needComputingRights) {
          if (rootGetters.hasComputingRights) {
            m.push(menu)
          }
          continue
        }
        // look for rights in children
        var itemsToShow = []
        for (var j = 0; j < menu.items.length; j++) {
          var item = menu.items[j]
          if (item.needSolvencyRights) {
            if (rootGetters.hasSolvencyRights) {
              itemsToShow.push(item)
            }
            continue
          }
          if (item.needEsgRights) {
            if (rootGetters.hasEsgRights) {
              itemsToShow.push(item)
            }
            continue
          }
          if (item.needRiskRights) {
            if (rootGetters.hasRiskRights) {
              itemsToShow.push(item)
            }
            continue
          }
          if (item.needSavingRights) {
            if (rootGetters.hasSavingRights) {
              itemsToShow.push(item)
            }
            continue
          }
          if (item.needComputingRights) {
            if (rootGetters.hasComputingRights) {
              itemsToShow.push(item)
            }
            continue
          }
          itemsToShow.push(item)
        }
        // add the module only if some children are accessible
        if (itemsToShow.length > 0) {
          menu.items = itemsToShow
          m.push(menu)
        }
      }
      return m
    },
  },
  mutations: {
    RESET (state) {
      var portfolioTitle = 'Portefeuille'
      if (this.getters.hasSimulationRights) {
        portfolioTitle = 'Portefeuille/Simulation'
      }
      state.menus = [
        {
          moduleTitle: undefined,
          moduleTitleMini: undefined,
          items: [
            {
              icon: 'mdi-home',
              title: 'Accueil',
              to: '/',
            },
            {
              icon: 'mdi-progress-upload',
              title: 'Intégration',
              to: '/integration',
              rolenameDependent: true,
            },
          ],
        },
        {
          moduleTitle: 'TRANSPARISATION',
          moduleTitleMini: 'TRANSPA',
          items: [
            {
              icon: 'mdi-clipboard-list',
              title: portfolioTitle,
              to: '/transparisation/portfolio',
              dataDependent: true,
            },
            {
              icon: 'mdi-view-dashboard',
              title: 'Indicateurs Transparisés',
              to: '/transparisation/reporting',
              dataDependent: true,
              simulationTranspaDependent: true,
            },
            {
              group: '/transparisation/archives',
              icon: 'mdi-archive',
              title: 'Analyse',
              rolenameDependent: true,
              children: [
                {
                  title: 'Rapports Détaillés',
                  to: 'details',
                  rolenameDependent: true,
                  archiveTranspaDependent: true,
                },
                {
                  title: 'Analyse Historique',
                  to: 'historical',
                  rolenameDependent: true,
                  archiveTranspaDependent: true,
                },
                {
                  title: 'Etude Comparative',
                  to: 'comparison',
                  rolenameDependent: true,
                  archiveTranspaDependent: true,
                },
              ],
            },
          ],
        },
        {
          moduleTitle: 'PARAMETRES',
          moduleTitleMini: 'PARAM',
          items: [
            {
              group: '',
              icon: 'mdi-cable-data',
              title: 'Solvabilité II',
              dataDependent: true,
              simulationTranspaDependent: true,
              needSolvencyRights: true,
              children: [
                {
                  title: 'Paramètres SCR',
                  to: 'solvency/home',
                  dataDependent: true,
                  simulationTranspaDependent: true,
                },
                {
                  group: '',
                  title: 'Données Client',
                  dataDependent: true,
                  simulationTranspaDependent: true,
                  children: [
                    {
                      title: 'Participations Stratégiques',
                      to: 'solvency/clientdata/strategicparticipations',
                      dataDependent: true,
                      simulationTranspaDependent: true,
                    },
                    {
                      title: 'LTEI',
                      to: 'solvency/clientdata/ltei',
                      dataDependent: true,
                      simulationTranspaDependent: true,
                    },
                    {
                      title: 'Intra Groupe',
                      to: 'solvency/clientdata/intragroup',
                      dataDependent: true,
                      simulationTranspaDependent: true,
                    },
                    {
                      title: 'Exclusion SCR Concentration',
                      to: 'solvency/clientdata/concentrationexclusion',
                      dataDependent: true,
                      simulationTranspaDependent: true,
                    },
                    {
                      title: 'Taux Endettement',
                      to: 'solvency/clientdata/propertyleverage',
                      dataDependent: true,
                      simulationTranspaDependent: true,
                    },
                  ],
                },
              ],
            },
            {
              group: '',
              icon: 'mdi-cable-data',
              title: 'ESG',
              dataDependent: true,
              simulationTranspaDependent: true,
              needEsgRights: true,
              children: [
                {
                  title: 'Paramètres ESG',
                  to: 'esg/parameters',
                  dataDependent: true,
                  simulationTranspaDependent: true,
                },
                {
                  title: 'Notations Forcées',
                  to: 'esg/noteexceptions',
                  dataDependent: true,
                  simulationTranspaDependent: true,
                },
              ],
            },
            {
              group: '',
              icon: 'mdi-cable-data',
              title: 'Risque',
              dataDependent: true,
              simulationTranspaDependent: true,
              needRiskRights: true,
              children: [
                {
                  title: 'Scénarios Liquidité',
                  to: 'risk/parameters',
                  dataDependent: true,
                  simulationTranspaDependent: true,
                },
              ],
            },
          ],
        },
        {
          moduleTitle: undefined,
          moduleTitleMini: undefined,
          items: [
            {
              icon: 'mdi-reload',
              title: 'Calculs',
              to: '/compute',
              dataDependent: true,
              simulationTranspaDependent: true,
              needComputingRights: true,
            },
            {
              title: 'Gestion des Sauvegardes',
              icon: 'mdi-archive',
              to: '/archives',
              rolenameDependent: true,
              needSavingRights: true,
            },
          ],
        },
        {
          moduleTitle: 'SOLVABILITE II',
          moduleTitleMini: 'S II',
          needSolvencyRights: true,
          items: [
            {
              icon: 'mdi-chart-donut-variant',
              title: 'Résultats',
              to: '/solvency/results',
              dataDependent: true,
              scrDependent: true,
              scrChangingDependent: true,
              simulationTranspaDependent: true,
            },
            {
              group: '/solvency/archives',
              icon: 'mdi-archive',
              title: 'Analyse',
              rolenameDependent: true,
              children: [
                {
                  title: 'Rapports Détaillés',
                  to: 'details',
                  rolenameDependent: true,
                  archiveSCRDependent: true,
                },
                {
                  title: 'Analyse Historique',
                  to: 'historical',
                  rolenameDependent: true,
                  archiveSCRDependent: true,
                },
                {
                  title: 'Etude Comparative',
                  to: 'comparison',
                  rolenameDependent: true,
                  archiveSCRDependent: true,
                },
              ],
            },
          ],
        },
        {
          moduleTitle: 'ESG',
          moduleTitleMini: 'ESG',
          needEsgRights: true,
          items: [
            {
              icon: 'mdi-chart-donut-variant',
              title: 'Résultats',
              to: '/esg/results',
              dataDependent: true,
              esgDependent: true,
              esgChangingDependent: true,
              simulationTranspaDependent: true,
            },
            {
              group: '/esg/archives',
              icon: 'mdi-archive',
              title: 'Analyse',
              rolenameDependent: true,
              children: [
                {
                  title: 'Rapports Détaillés',
                  to: 'details',
                  rolenameDependent: true,
                  archiveESGDependent: true,
                },
                {
                  title: 'Analyse Historique',
                  to: 'historical',
                  rolenameDependent: true,
                  archiveESGDependent: true,
                },
                {
                  title: 'Etude Comparative',
                  to: 'comparison',
                  rolenameDependent: true,
                  archiveESGDependent: true,
                },
                {
                  title: 'Température Implicite',
                  to: 'temperature',
                  rolenameDependent: true,
                  archiveESGDependent: true,
                },
              ],
            },
          ],
        },
        {
          moduleTitle: 'RISQUE',
          moduleTitleMini: 'RISQUE',
          needRiskRights: true,
          items: [
            {
              icon: 'mdi-chart-donut-variant',
              title: 'Résultats',
              to: '/risk/results',
              dataDependent: true,
              riskDependent: true,
              riskChangingDependent: true,
              simulationTranspaDependent: true,
            },
            {
              group: '/risk/archives',
              icon: 'mdi-archive',
              title: 'Analyse',
              rolenameDependent: true,
              children: [
                {
                  title: 'Rapports Détaillés',
                  to: 'details',
                  rolenameDependent: true,
                  archiveRiskDependent: true,
                },
              ],
            },
          ],
        },
        {
          moduleTitle: undefined,
          items: [
            {
              icon: 'mdi-help-circle',
              title: 'Aide',
              to: '/help',
            },
          ],
        },
      ]
    },
  },
  actions: {
  },
}
