import parameters from './parameters'
import results from './results'
import archives from './archives'

export default ({
  namespaced: true,
  modules: {
    parameters: parameters,
    results: results,
    archives: archives,
  },
  mutations: {
    RESET (state) {
      this.commit('solvency/parameters/RESET', null, { root: true })
      this.commit('solvency/results/RESET', null, { root: true })
      this.commit('solvency/archives/RESET', null, { root: true })
    },
  },
})
