export default {
  namespaced: true,
  state: {
    detailsPbi: undefined,
  },
  getters: {
    detailsPbi: state => {
      return state.detailsPbi
    },
  },
  mutations: {
    RESET (state) {
      state.detailsPbi = undefined
    },

    SET_PBIS: function (state) {
      state.detailsPbi = {
        groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
        reportId: process.env.VUE_APP_POWERBI_RISKLIQUIDITYARCHIVESDETAILS_REPORTID,
      }
    },
  },
  actions: {
  },
}
