export default {
  namespaced: true,
  state: {
    loadingAdmin: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement du périmètre.',
      retryFunction: 'get_admin',
      commitFunction: 'errors/SET_ADMIN_ERROR_LOADING',
    },
    loadingPerimeter: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement du périmètre.',
      retryFunction: 'perimeter/load_perimeter',
      commitFunction: 'errors/SET_PERIMETER_ERROR_LOADING',
    },
    // savingPerimeter: {
    //   value: false,
    //   message: 'Une erreur est survenue pendant l\'enregistrement du périmètre.',
    //   retryFunction: 'perimeter/save_perimeter',
    //   commitFunction: 'errors/SET_PERIMETER_ERROR_SAVING',
    // },
    loadingRolename: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement du périmètre.',
      retryFunction: 'perimeter/get_rolename',
      commitFunction: 'errors/SET_ROLENAME_ERROR_LOADING',
    },
    loadingRolenames: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des clients.',
      retryFunction: 'perimeter/load_rolenames',
      commitFunction: 'errors/SET_ROLENAMES_ERROR_LOADING',
    },
    loadingPortfolios: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des portefeuilles.',
      retryFunction: 'perimeter/load_portfolios',
      commitFunction: 'errors/SET_PORTFOLIOS_ERROR_LOADING',
    },
    loadingValuationDate: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des dates.',
      retryFunction: 'perimeter/load_dates',
      commitFunction: 'errors/SET_DATES_ERROR_LOADING',
    },
    loadingCompartments: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des compartiments.',
      retryFunction: 'perimeter/load_compartments',
      commitFunction: 'errors/SET_COMPARTMENTS_ERROR_LOADING',
    },
    loadingEquity: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres action.',
      retryFunction: 'solvency/parameters/load_equity_parameters',
      commitFunction: 'errors/SET_EQUITY_ERROR_LOADING',
    },
    savingEquity: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres action.',
      retryFunction: 'solvency/parameters/save_equity_parameters',
      commitFunction: 'errors/SET_EQUITY_ERROR_SAVING',
    },
    loadingYieldCurves: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des courbes de taux.',
      retryFunction: 'solvency/parameters/getScrReference',
      commitFunction: 'errors/SET_YIELDCURVES_ERROR_LOADING',
    },
    loadingScrReference: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres EIOPA.',
      retryFunction: 'solvency/parameters/load_yieldCurves',
      commitFunction: 'errors/SET_SCRREFERENCE_ERROR_LOADING',
    },
    loadingDurations: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des différentes durations possibles.',
      retryFunction: 'solvency/parameters/load_durations',
      commitFunction: 'errors/SET_DURATIONS_ERROR_LOADING',
    },
    loadingRate: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres de taux.',
      retryFunction: 'solvency/parameters/load_interestrate_parameters',
      commitFunction: 'errors/SET_RATE_ERROR_LOADING',
    },
    savingRate: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres de taux.',
      retryFunction: 'solvency/parameters/save_interestrate_parameters',
      commitFunction: 'errors/SET_RATE_ERROR_SAVING',
    },
    loadingProperty: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres immobilier.',
      retryFunction: 'solvency/parameters/load_property_parameters',
      commitFunction: 'errors/SET_PROPERTY_ERROR_LOADING',
    },
    savingProperty: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres immobilier.',
      retryFunction: 'solvency/parameters/save_property_parameters',
      commitFunction: 'errors/SET_PROPERTY_ERROR_SAVING',
    },
    loadingCurrency: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres de change.',
      retryFunction: 'solvency/parameters/load_currency_parameters',
      commitFunction: 'errors/SET_CURRENCY_ERROR_LOADING',
    },
    savingCurrency: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres de change.',
      retryFunction: 'solvency/parameters/save_currency_parameters',
      commitFunction: 'errors/SET_CURRENCY_ERROR_SAVING',
    },
    loadingCurrencies: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des devises.',
      retryFunction: 'solvency/parameters/load_currencies',
      commitFunction: 'errors/SET_CURRENCIES_ERROR_LOADING',
    },
    loadingHierarchicalPositions: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des données des inventaires. Veuillez réessayer.',
      retryFunction: 'transparisation/refresh',
      commitFunction: 'errors/SET_HIERARCHICALPOSITIONS_ERROR_LOADING',
    },
    savingHierarchicalPositions: {
      value: false,
      message: 'Une erreur est survenue pendant la transparisation. Veuillez réessayer.',
      retryFunction: 'transparisation/computeSaveHierarchicalPositions',
      commitFunction: 'errors/SET_HIERARCHICALPOSITIONS_ERROR_SAVING',
    },
    loadingStrategicParticipations: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des participations stratégiques.',
      retryFunction: 'solvency/parameters/load_strategicparticipations',
      commitFunction: 'errors/SET_STRATEGICPARTICIPATIONS_ERROR_LOADING',
    },
    savingStrategicParticipations: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des participations stratégiques.',
      retryFunction: 'solvency/parameters/saveStrategicParticipationsFromValue',
      commitFunction: 'errors/SET_STRATEGICPARTICIPATIONS_ERROR_SAVING',
    },
    loadingCIC: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des codes CIC.',
      retryFunction: 'solvency/parameters/load_strategicparticipations_cic',
      commitFunction: 'errors/SET_CIC_ERROR_LOADING',
    },
    generatingSCR: {
      value: false,
      message: 'Une erreur est survenue pendant la génération du rapport SCR.',
      retryFunction: 'solvency/results/generate_scr_report',
      commitFunction: 'errors/SET_REPORTS_SCR_ERROR',
    },
    generatingQRT: {
      value: false,
      message: 'Une erreur est survenue pendant la génération du rapport QRT.',
      retryFunction: 'solvency/results/generate_qrt_report',
      commitFunction: 'errors/SET_REPORTS_QRT_ERROR',
    },
    generatingActuaris: {
      value: false,
      message: 'Une erreur est survenue pendant la génération du rapport Actuaris.',
      retryFunction: 'solvency/results/generate_actuaris_report',
      commitFunction: 'errors/SET_REPORTS_ACTUARIS_ERROR',
    },
    generatingAssuretat: {
      value: false,
      message: 'Une erreur est survenue pendant la génération du rapport Assuretat.',
      retryFunction: 'solvency/results/generate_assuretat_report',
      commitFunction: 'errors/SET_REPORTS_ASSURETAT_ERROR',
    },
    generatingTranspaReport: {
      value: false,
      message: 'Une erreur est survenue pendant la génération du rapport de transparisation.',
      retryFunction: 'transparisation/exportTranspaExcel',
      commitFunction: 'errors/SET_TRANSPAREPORTLOADING',
    },
    loadingNews: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des actualités.',
      retryFunction: 'news/getSharepointNews',
      commitFunction: 'errors/SET_LOADINGNEWS_ERROR',
    },
    loadingFiles: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des fichiers intégrés.',
      retryFunction: 'integration/getSharepointFiles',
      commitFunction: 'errors/SET_LOADINGFILES_ERROR',
    },
    loadingPbi: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement du rapport.',
      retryFunction: 'transparisation/refresh', // reload page pbi
      commitFunction: 'errors/SET_LOADINGPBI_ERROR',
    },
    loadingArchives: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des sauvegardes.',
      retryFunction: 'archives/getArchives',
      commitFunction: 'errors/SET_LOADINGARCHIVES_ERROR',
    },
    loadingArchivesCategories: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des catégories des sauvegardes.',
      retryFunction: 'archives/getCategories',
      commitFunction: 'errors/SET_LOADINGARCHIVESCATEGORIES_ERROR',
    },
    creatingArchive: {
      value: false,
      message: 'Une erreur est survenue pendant la création de la sauvegarde. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_CREATINGARCHIVE_ERROR',
    },
    updatingArchive: {
      value: false,
      message: 'Une erreur est survenue pendant la modification de la sauvegarde. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_UPDATINGARCHIVE_ERROR',
    },
    deletingArchive: {
      value: false,
      message: 'Une erreur est survenue pendant la suppression de la sauvegarde. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_DELETINGARCHIVE_ERROR',
    },
    creatingSimulationID: {
      value: false,
      message: 'Une erreur est survenue pendant le passage en mode simulation. Veuillez réessayer.',
      retryFunction: 'simulation/createSimulationFromPerimeter',
      commitFunction: 'errors/SET_CREATINGSIMULATIONID_ERROR',
    },
    transparisationSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la transparisation du portefeuille simulé. Veuillez réessayer.',
      retryFunction: 'simulation/computeSaveHierarchicalPositions',
      commitFunction: 'errors/SET_TRANSPARISATIONSIMULATION_ERROR',
    },
    readingPositionsSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement de l\'inventaire. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_READINGPOSITIONSSIMULATION_ERROR',
    },
    updatingPositionsSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la modification de la ligne. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_UPDATINGPOSITIONSSIMULATION_ERROR',
    },
    deletingPositionsSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la suppression de la ligne. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_DELETINGPOSITIONSSIMULATION_ERROR',
    },
    readingSinglePositionSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la mise à jour de la ligne modifiée. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_READINGSINGLEPOSITIONSIMULATION_ERROR',
    },
    creatingPositionsSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la création de la position. Veuillez réessayer.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_CREATINGPOSITIONSSIMULATION_ERROR',
    },
    loadingCurrenciesSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des devises.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_LOADINGCURRENCIESSIMULATION_ERROR',
    },
    readingTitresInsertSimulation: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des titres.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_READINGTITRESINSERTSIMULATION_ERROR',
    },
    updatingQuantitySimulation: {
      value: false,
      message: 'Une erreur est survenue pendant la mise à jour de la quantité.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_UPDATINGQUANTITYSIMULATION_ERROR',
    },
    lteiLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des LTEI.',
      retryFunction: 'solvency/parameters/load_ltei',
      commitFunction: 'errors/SET_LTEI_ERROR_LOADING',
    },
    lteiSavingValue: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des LTEI.',
      retryFunction: 'solvency/parameters/saveLteiFromValue',
      commitFunction: 'errors/SET_LTEI_ERROR_SAVINGVALUE',
    },
    cicLteiLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des LTEI.',
      retryFunction: 'solvency/parameters/load_ltei_cic',
      commitFunction: 'errors/SET_CICLTEI_ERROR_LOADING',
    },
    intraGroupLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des actifs intra groupe.',
      retryFunction: 'solvency/parameters/load_intraGroup',
      commitFunction: 'errors/SET_INTRAGROUP_ERROR_LOADING',
    },
    intraGroupSavingValue: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des actifs intra groupe.',
      retryFunction: 'solvency/parameters/saveIntraGroupFromValue',
      commitFunction: 'errors/SET_INTRAGROUP_ERROR_SAVINGVALUE',
    },
    concentrationExclusionLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des titres exclus du SCR concentration.',
      retryFunction: 'solvency/parameters/load_concentrationExclusion',
      commitFunction: 'errors/SET_CONCENTRATIONEXCLUSION_ERROR_LOADING',
    },
    concentrationExclusionSavingValue: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des titres exclus du SCR concentration.',
      retryFunction: 'solvency/parameters/saveConcentrationExclusionFromValue',
      commitFunction: 'errors/SET_CONCENTRATIONEXCLUSION_ERROR_SAVINGVALUE',
    },
    propertyLeverageLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des titres avec taux d\'endettement.',
      retryFunction: 'solvency/parameters/load_propertyLeverage',
      commitFunction: 'errors/SET_PROPERTYLEVERAGE_ERROR_LOADING',
    },
    propertyLeverageSavingValue: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des titres avec taux d\'endettement.',
      retryFunction: 'solvency/parameters/savePropertyLeverageFromValue',
      commitFunction: 'errors/SET_PROPERTYLEVERAGE_ERROR_SAVINGVALUE',
    },
    cicPropertyLeverageLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des titres avec taux d\'endettement.',
      retryFunction: 'solvency/parameters/load_propertyLeverage_cic',
      commitFunction: 'errors/SET_CICPROPERTYLEVERAGE_ERROR_LOADING',
    },
    cashsLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des montants des différents comptes de cash.',
      retryFunction: 'simulation/getCashs',
      commitFunction: 'errors/SET_CASHSLOADING_ERROR',
    },
    currentMarketValuationLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement de la valeur boursière courante du portefeuille.',
      retryFunction: 'simulation/getCurrentMarketValuation',
      commitFunction: 'errors/SET_CURRENTMARKETVALUATIONLOADING_ERROR',
    },
    initialMarketValuationLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement de la valeur boursière initiale du portefeuille.',
      retryFunction: 'simulation/getInitialMarketValuation',
      commitFunction: 'errors/SET_INITIALMARKETVALUATIONLOADING_ERROR',
    },
    getEsgPiliers: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres de piliers.',
      retryFunction: 'esg/parameters/getPiliers',
      commitFunction: 'errors/SET_GETPILIERS_ERROR',
    },
    setEsgPiliers: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres de piliers.',
      retryFunction: 'esg/parameters/setPiliers',
      commitFunction: 'errors/SET_SETPILIERS_ERROR',
    },
    getEsgThemes: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des paramètres de thèmes.',
      retryFunction: 'esg/parameters/getThemes',
      commitFunction: 'errors/SET_GETTHEMES_ERROR',
    },
    setEsgThemes: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des paramètres de thèmes.',
      retryFunction: 'esg/parameters/setThemes',
      commitFunction: 'errors/SET_SETTHEMES_ERROR',
    },
    getEsgSectors: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des différents secteurs.',
      retryFunction: 'esg/parameters/getSectors',
      commitFunction: 'errors/SET_GETSECTORS_ERROR',
    },
    notationsLoading: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des notations forcées.',
      retryFunction: 'solvency/parameters/load_notations',
      commitFunction: 'errors/SET_NOTATIONS_ERROR_LOADING',
    },
    notationsSavingValue: {
      value: false,
      message: 'Une erreur est survenue pendant l\'enregistrement des notations forcées.',
      retryFunction: 'solvency/parameters/saveNotationsFromValue',
      commitFunction: 'errors/SET_NOTATIONS_ERROR_SAVINGVALUE',
    },
    computing: {
      value: false,
      message: 'Une erreur est survenue pendant les calculs.',
      retryFunction: undefined,
      commitFunction: 'errors/SET_COMPUTING_ERROR',
    },
    riskScenariosGetting: {
      value: false,
      message: 'Une erreur est survenue pendant le chargement des scénarios de risque.',
      retryFunction: 'risk/parameters/getScenarios',
      commitFunction: 'errors/SET_GETSCENARIOS_ERROR',
    },
  },
  getters: {
    errors: state => {
      return state
    },
  },
  mutations: {
    RESET (state) {
      for (var i = 0; i < Object.keys(state).length; i++) {
        state[Object.keys(state)[i]].value = false
      }
    },
    SET_NOTATIONS_ERROR_LOADING (state, payload) {
      state.notationsLoading.value = payload
    },
    SET_NOTATIONS_ERROR_SAVINGVALUE (state, payload) {
      state.notationsSavingValue.value = payload
    },
    SET_COMPUTING_ERROR (state, payload) {
      state.computing.value = payload
    },
    SET_GETPILIERS_ERROR (state, payload) {
      state.getEsgPiliers.value = payload
    },
    SET_SETPILIERS_ERROR (state, payload) {
      state.setEsgPiliers.value = payload
    },
    SET_GETTHEMES_ERROR (state, payload) {
      state.getEsgThemes.value = payload
    },
    SET_SETTHEMES_ERROR (state, payload) {
      state.setEsgThemes.value = payload
    },
    SET_GETSECTORS_ERROR (state, payload) {
      state.getEsgSectors.value = payload
    },
    SET_CASHSLOADING_ERROR (state, payload) {
      state.cashsLoading.value = payload
    },
    SET_CURRENTMARKETVALUATIONLOADING_ERROR (state, payload) {
      state.currentMarketValuationLoading.value = payload
    },
    SET_INITIALMARKETVALUATIONLOADING_ERROR (state, payload) {
      state.initialMarketValuationLoading.value = payload
    },
    SET_LTEI_ERROR_LOADING (state, payload) {
      state.lteiLoading.value = payload
    },
    SET_LTEI_ERROR_SAVINGVALUE (state, payload) {
      state.lteiSavingValue.value = payload
    },
    SET_CICLTEI_ERROR_LOADING (state, payload) {
      state.cicLteiLoading.value = payload
    },
    SET_INTRAGROUP_ERROR_LOADING (state, payload) {
      state.intraGroupLoading.value = payload
    },
    SET_INTRAGROUP_ERROR_SAVINGVALUE (state, payload) {
      state.intraGroupSavingValue.value = payload
    },
    SET_CONCENTRATIONEXCLUSION_ERROR_LOADING (state, payload) {
      state.concentrationExclusionLoading.value = payload
    },
    SET_CONCENTRATIONEXCLUSION_ERROR_SAVINGVALUE (state, payload) {
      state.concentrationExclusionSavingValue.value = payload
    },
    SET_PROPERTYLEVERAGE_ERROR_LOADING (state, payload) {
      state.propertyLeverageLoading.value = payload
    },
    SET_PROPERTYLEVERAGE_ERROR_SAVINGVALUE (state, payload) {
      state.propertyLeverageSavingValue.value = payload
    },
    SET_CICPROPERTYLEVERAGE_ERROR_LOADING (state, payload) {
      state.cicPropertyLeverageLoading.value = payload
    },
    SET_CREATINGSIMULATIONID_ERROR (state, payload) {
      state.creatingSimulationID.value = payload
    },
    SET_READINGPOSITIONSSIMULATION_ERROR (state, payload) {
      state.readingPositionsSimulation.value = payload
    },
    SET_UPDATINGPOSITIONSSIMULATION_ERROR (state, payload) {
      state.updatingPositionsSimulation.value = payload
    },
    SET_DELETINGPOSITIONSSIMULATION_ERROR (state, payload) {
      state.deletingPositionsSimulation.value = payload
    },
    SET_READINGSINGLEPOSITIONSIMULATION_ERROR (state, payload) {
      state.readingSinglePositionSimulation.value = payload
    },
    SET_CREATINGPOSITIONSSIMULATION_ERROR (state, payload) {
      state.creatingPositionsSimulation.value = payload
    },
    SET_LOADINGCURRENCIESSIMULATION_ERROR (state, payload) {
      state.loadingCurrenciesSimulation.value = payload
    },
    SET_READINGTITRESINSERTSIMULATION_ERROR (state, payload) {
      state.readingTitresInsertSimulation.value = payload
    },
    SET_UPDATINGQUANTITYSIMULATION_ERROR (state, payload) {
      state.updatingQuantitySimulation.value = payload
    },
    SET_TRANSPARISATIONSIMULATION_ERROR (state, payload) {
      state.transparisationSimulation.value = payload
    },
    SET_LOADINGNEWS_ERROR (state, payload) {
      state.loadingNews.value = payload
    },
    SET_LOADINGFILES_ERROR (state, payload) {
      state.loadingFiles.value = payload
    },
    SET_LOADINGPBI_ERROR (state, payload) {
      state.loadingPbi.value = payload
    },
    SET_ADMIN_ERROR_LOADING (state, payload) {
      state.loadingAdmin.value = payload
    },
    SET_PERIMETER_ERROR_LOADING (state, payload) {
      state.loadingPerimeter.value = payload
    },
    // SET_PERIMETER_ERROR_SAVING (state, payload) {
    //   state.savingPerimeter.value = payload
    // },
    SET_ROLENAMES_ERROR_LOADING (state, payload) {
      state.loadingRolenames.value = payload
    },
    SET_ROLENAME_ERROR_LOADING (state, payload) {
      state.loadingRolename.value = payload
    },
    SET_PORTFOLIOS_ERROR_LOADING (state, payload) {
      state.loadingPortfolios.value = payload
    },
    SET_DATES_ERROR_LOADING (state, payload) {
      state.loadingValuationDate.value = payload
    },
    SET_COMPARTMENTS_ERROR_LOADING (state, payload) {
      state.loadingCompartments.value = payload
    },
    SET_EQUITY_ERROR_LOADING (state, payload) {
      state.loadingEquity.value = payload
      this.commit('solvency/parameters/SET_PARAMETERSSAVING', false)
    },
    SET_EQUITY_ERROR_SAVING (state, payload) {
      state.savingEquity.value = payload
    },
    SET_YIELDCURVES_ERROR_LOADING (state, payload) {
      state.loadingYieldCurves.value = payload
    },
    SET_DURATIONS_ERROR_LOADING (state, payload) {
      state.loadingDurations.value = payload
    },
    SET_RATE_ERROR_LOADING (state, payload) {
      state.loadingRate.value = payload
      this.commit('solvency/parameters/SET_PARAMETERSSAVING', false)
    },
    SET_RATE_ERROR_SAVING (state, payload) {
      state.savingRate.value = payload
    },
    SET_PROPERTY_ERROR_LOADING (state, payload) {
      state.loadingProperty.value = payload
      this.commit('solvency/parameters/SET_PARAMETERSSAVING', false)
    },
    SET_PROPERTY_ERROR_SAVING (state, payload) {
      state.savingProperty.value = payload
    },
    SET_CURRENCY_ERROR_LOADING (state, payload) {
      state.loadingCurrency.value = payload
      this.commit('solvency/parameters/SET_PARAMETERSSAVING', false)
    },
    SET_CURRENCIES_ERROR_LOADING (state, payload) {
      state.loadingCurrencies.value = payload
      this.commit('solvency/parameters/SET_PARAMETERSSAVING', false)
    },
    SET_CURRENCY_ERROR_SAVING (state, payload) {
      state.savingCurrency.value = payload
    },
    SET_SCRREFERENCE_ERROR_LOADING (state, payload) {
      state.loadingScrReference.value = payload
    },
    SET_HIERARCHICALPOSITIONS_ERROR_LOADING (state, payload) {
      state.loadingHierarchicalPositions.value = payload
    },
    SET_HIERARCHICALPOSITIONS_ERROR_SAVING (state, payload) {
      state.savingHierarchicalPositions.value = payload
    },
    SET_STRATEGICPARTICIPATIONS_ERROR_LOADING (state, payload) {
      state.loadingStrategicParticipations.value = payload
    },
    SET_CIC_ERROR_LOADING (state, payload) {
      state.loadingCIC.value = payload
    },
    SET_STRATEGICPARTICIPATIONS_ERROR_SAVING (state, payload) {
      state.savingStrategicParticipations.value = payload
    },
    SET_REPORTS_SCR_ERROR (state, payload) {
      state.generatingSCR.value = payload
    },
    SET_REPORTS_QRT_ERROR (state, payload) {
      state.generatingQRT.value = payload
    },
    SET_REPORTS_ACTUARIS_ERROR (state, payload) {
      state.generatingActuaris.value = payload
    },
    SET_REPORTS_ASSURETAT_ERROR (state, payload) {
      state.generatingAssuretat.value = payload
    },
    SET_LOADINGARCHIVES_ERROR (state, payload) {
      state.loadingArchives.value = payload
    },
    SET_LOADINGARCHIVESCATEGORIES_ERROR (state, payload) {
      state.loadingArchivesCategories.value = payload
    },
    SET_CREATINGARCHIVE_ERROR (state, payload) {
      state.creatingArchive.value = payload
    },
    SET_UPDATINGARCHIVE_ERROR (state, payload) {
      state.updatingArchive.value = payload
    },
    SET_DELETINGARCHIVE_ERROR (state, payload) {
      state.deletingArchive.value = payload
    },
    SET_GETSCENARIOS_ERROR (state, payload) {
      state.riskScenariosGetting.value = payload
    },
    SET_REPORTS_TRANSPA_ERROR (state, payload) {
      state.generatingTranspaReport.value = payload
    },
  },
  actions: {

  },
}
