import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Axios from 'axios'

import perimeterModule from './general/perimeter'
import errorsModule from './general/errors'
import menuModule from './general/menu'
import solvencyModule from './solvency/index'
import transparisationModule from './transpa/index'
import newsModule from './general/news'
import integrationModule from './general/integration'
import archivesModule from './general/archives'
import simulationModule from './simulation/index'
import esgModule from './esg/index'
import riskModule from './risk/index'

Vue.use(Vuex)
const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.sessionStorage,
})
console.log("BASE_URL: " + process.env.VUE_APP_BASE_URL)
console.log("BASE_URL_WEB: " + process.env.VUE_APP_BASE_URL_WEB)
export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    perimeter: perimeterModule,
    errors: errorsModule,
    news: newsModule,
    integration: integrationModule,
    solvency: solvencyModule,
    transparisation: transparisationModule,
    simulation: simulationModule,
    esg: esgModule,
    risk: riskModule,
    archives: archivesModule,
    menu: menuModule,
  },
  state: {
    barColor: 'rgb(34, 32, 32)',
    drawer: null,
    expandOnHover: false,
    menuOpened: false,
    baseUrl: process.env.VUE_APP_BASE_URL,
    userAccount: undefined,
    isAdmin: false,
    hasTransparencyRights: true,
    hasSimulationRights: false,
    hasSolvencyRights: false,
    hasEsgRights: false,
    hasRiskRights: true,
    windowSize: 1200,
    resetLogin: false,
    openedTab: false,
  },
  getters: {
    baseUrl: state => {
      return state.baseUrl
    },
    hasSolvencyRights: state => {
      return state.hasSolvencyRights
    },
    hasSimulationRights: state => {
      return state.hasSimulationRights
    },
    hasEsgRights: state => {
      return state.hasEsgRights
    },
    hasSavingRights: state => {
      return true // state.hasEsgRights || state.hasSolvencyRights || state.hasRiskRights
    },
    hasComputingRights: state => {
      return state.hasEsgRights || state.hasSolvencyRights || state.hasRiskRights
    },
    hasRiskRights: state => {
      return state.hasRiskRights
    },
    numberModulesShortcuts: state => {
      var nb = 0
      nb += state.hasSolvencyRights ? 1 : 0
      nb += state.hasEsgRights ? 1 : 0
      nb += state.hasRiskRights ? 1 : 0
      return nb
    },
    isAdmin: state => {
      return state.isAdmin
    },
    drawer: state => {
      return state.drawer
    },
    expandOnHover: state => {
      return state.expandOnHover
    },
    resetLogin: state => {
      return state.resetLogin
    },
  },
  mutations: {
    RESET(state) {
      state.barColor = 'rgb(34, 32, 32)'
      state.drawer = null
      state.expandOnHover = false
      state.menuOpened = false
      state.baseUrl = process.env.VUE_APP_BASE_URL
      state.userAccount = undefined
      state.isAdmin = false
      state.hasTransparencyRights = true
      state.hasSimulationRights = false
      state.hasSolvencyRights = false
      state.hasEsgRights = false
      state.hasRiskRights = false
      state.windowSize = 1200
      state.resetLogin = false
      state.openedTab = false
      Vue.prototype.tabToReload = []
      this.commit('perimeter/RESET', null, { root: true })
      this.commit('menu/RESET', null, { root: true })
      this.commit('errors/RESET', null, { root: true })
      this.commit('news/RESET', null, { root: true })
      this.commit('integration/RESET', null, { root: true })
      this.commit('transparisation/RESET', null, { root: true })
      this.commit('solvency/RESET', null, { root: true })
      this.commit('esg/RESET', null, { root: true })
      this.commit('risk/RESET', null, { root: true })
      this.commit('archives/RESET', null, { root: true })
      this.commit('simulation/RESET_SIMULATION', null, { root: true })
    },
    SET_RESETLOGIN(state, payload) {
      state.resetLogin = payload
    },
    SET_BASEURL(state) {
      state.baseUrl = process.env.VUE_APP_BASE_URL
      this.commit('transparisation/SET_PBI', undefined, { root: true })
      this.commit('solvency/archives/SET_PBIS', undefined, { root: true })
      this.commit('esg/archives/SET_PBIS', undefined, { root: true })
      this.commit('risk/archives/SET_PBIS', undefined, { root: true })
      this.commit('transparisation/archives/SET_PBIS', undefined, { root: true })
    },
    SET_USER_ACCOUNT(state) {
      state.userAccount = Vue.prototype.$AuthService.getAccount()
    },
    SET_HASTRANSPARENCYRIGHTS(state, payload) {
      state.hasTransparencyRights = true
      this.commit('menu/RESET', null, { root: true })
    },
    SET_HASSIMULATIONRIGHTS(state, payload) {
      state.hasSimulationRights = state.isAdmin ? true : payload
      this.commit('menu/RESET', null, { root: true })
    },
    SET_HASSOLVENCYRIGHTS(state, payload) {
      state.hasSolvencyRights = state.isAdmin ? true : payload
      this.commit('menu/RESET', null, { root: true })
    },
    SET_HASESGRIGHTS(state, payload) {
      state.hasEsgRights = state.isAdmin ? true : payload
      this.commit('menu/RESET', null, { root: true })
    },
    SET_HASRISKRIGHTS(state, payload) {
      state.hasRiskRights = state.isAdmin ? true : payload
      this.commit('menu/RESET', null, { root: true })
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_EXPANDONHOVER(state, payload) {
      state.expandOnHover = payload
      state.drawer = !payload // close drawer if expand on hover, open if not expand ie keep open
    },
    SET_MENUOPENED(state, payload) {
      state.menuOpened = payload
    },
    SET_WINDOWSIZE(state, payload) {
      state.windowSize = payload
    },
    SET_ISADMIN(state, payload) {
      state.isAdmin = payload
      if (payload) {
        this.commit('SET_HASTRANSPARENCYRIGHTS', true)
        this.commit('SET_HASSIMULATIONRIGHTS', true)
        this.commit('SET_HASSOLVENCYRIGHTS', true)
        this.commit('SET_HASESGRIGHTS', true)
        this.commit('SET_HASRISKRIGHTS', true)
      }
    },
    SET_OPENEDTAB(state, payload) {
      state.openedTab = payload
    },
  },
  actions: {
    get_admin: function ({ commit, dispatch }) {
      commit('SET_BASEURL', null, { root: true })
      commit('SET_WINDOWSIZE', window.innerWidth, { root: true })
      window.onresize = function () {
        commit('SET_WINDOWSIZE', window.innerWidth, { root: true })
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/arago/admin',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            this.commit('errors/SET_ADMIN_ERROR_LOADING', false, { root: true })
            this.commit('SET_ISADMIN', response.data, { root: true })
            if (!response.data) {
              this.dispatch('perimeter/get_rolename', { root: true })
            }
          })
          .catch(() => {
            Vue.prototype.$AuthService.logout()
            this.commit('errors/SET_ADMIN_ERROR_LOADING', true, { root: true })
            this.commit('SET_ISADMIN', false, { root: true })
            this.dispatch('perimeter/get_rolename', { root: true })
          })
      })
    },
    refreshOpenedTab: function () {
      if (this.state.openedTab) {
        for (var i = 0; i < Vue.prototype.tabToReload.length; i++) {
          Vue.prototype.tabToReload[i].location.reload()
        }
      }
    },
    openReportInTab: function ({ commit }, keepRef) {
      var to = window.location.origin + '/#/arago-reports' + window.location.hash.substring(1)
      if (keepRef) {
        if (!Vue.prototype.tabToReload) {
          Vue.prototype.tabToReload = []
        }
        Vue.prototype.tabToReload.push(window.open(to, '_blank'))
        commit('SET_OPENEDTAB', true, { root: true })
      } else {
        window.open(to, '_blank')
      }
    },
  },
})
