// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
//import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
// import '@progress/kendo-theme-default'
import './sass/kendocustom.css'
import kendo from '@progress/kendo-ui'

import '@progress/kendo-data-query'
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js'
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js'
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "@progress/kendo-ui";
import "./kendo-ui-license.js";
//import "@progress/kendo-licensing"
//import '@progress/kendo-ui/js/kendo.core';
//import "./kendo-ui-license.js"
// import KendoLicensing from "@progress/kendo-licensing"
// KendoLicensing.setScriptKey('141j044b041h541j4i1d542e582i511k0b1i111c0h1c0g1k131k0g1b0c2i4d3e112a612g5b2661204f275b2b5d1d002218201520161d0j1d1621072d513718305b27612h5b1c4k214g1k5g2b571f4d34564a504550444d3h4d444k355b1k054a5j2d592i5f2d4e215k1j543g4f3j4h414h40544854404g395f1g093j571g53236024541d572b122h0a2j0d2j0c2b042b0c2i0g1b5k1b0c243e243c5i3b5j3g5h434c044j06211a5g13603k510b290324185f145h1721195i3f5h3g6149583k0i474e062c4j12255j121b3f2k085f2d4d284d1j3h2e5c26424d1b18304e')

Vue.use(GridInstaller)
Vue.use(DataSourceInstaller)
kendo.culture('fr-FR')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
