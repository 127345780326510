import Vue from 'vue'
import Axios from 'axios'
import router from './../../router'

export default {
  namespaced: true,
  state: {
    simulationID: null,
    simulationIdLoaded: false,
    simulationMode: false,
    transparised: false,
    createDialogOpened: false,
    perimeterId: null,
    initialMarketValuation: null,
    initialMarketValuationLoaded: false,
    currentMarketValuation: null,
    cashs: [],
    cashsLoaded: false,
    quitSimulationPopupOpened: false,
  },
  getters: {
    createDialogOpened: state => {
      return state.createDialogOpened
    },
    simulationID: state => {
      return state.simulationID
    },
    simulationMode: state => {
      return state.simulationMode
    },
    perimeterId: state => {
      return state.perimeterId
    },
    transparised: state => {
      return state.transparised
    },
    initialMarketValuation: state => {
      return state.initialMarketValuation
    },
    currentMarketValuation: state => {
      return state.currentMarketValuation
    },
    cashs: state => {
      return state.cashs
    },
    quitSimulationPopupOpened: state => {
      return state.quitSimulationPopupOpened
    },
    simulationGridReady: state => {
      return state.simulationIdLoaded && state.cashsLoaded && state.initialMarketValuationLoaded
    },
  },
  mutations: {
    SET_CREATEDIALOGOPENED: function (state, payload) {
      state.createDialogOpened = payload
    },
    SET_QUITSIMULATIONPOPUPOPENED: function (state, payload) {
      state.quitSimulationPopupOpened = payload
    },
    SET_SIMULATIONID(state, payload) {
      state.simulationID = payload
    },
    RESET_SIMULATION(state) {
      state.simulationID = null
      state.simulationIdLoaded = false
      state.simulationMode = false
      state.transparised = false
      state.createDialogOpened = false
      state.perimeterId = null
      state.cashs = []
      state.cashsLoaded = false
      state.initialMarketValuation = null
      state.initialMarketValuationLoaded = false
      state.currentMarketValuation = null
      state.quitSimulationPopupOpened = false
    },
    SET_SIMULATIONMODE(state, payload) {
      state.simulationMode = payload
      if (!payload) {
        state.simulationID = null
        state.transparised = false
        state.perimeterId = null
      } else {
        this.commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
      }
    },
    SET_PERIMETERID(state, payload) {
      state.perimeterId = payload
      if (payload) {
        this.dispatch('refreshOpenedTab', undefined, { root: true })
      }
    },
    SET_SIMULATIONIDLOADED(state, payload) {
      state.simulationIdLoaded = payload
    },
    SET_TRANSPARISED(state, payload) {
      state.transparised = payload
    },
    SIMULATION_MODIFICATION(state) {
      state.transparised = false
      this.dispatch('simulation/getCashs', null, { root: true })
      this.dispatch('simulation/getCurrentMarketValuation', null, { root: true })
    },
    SET_CASHS(state, payload) {
      state.cashs = payload
    },
    SET_CASHSLOADED(state, payload) {
      state.cashsLoaded = payload
    },
    SET_INITIALMARKETVALUATION(state, payload) {
      state.initialMarketValuation = payload
    },
    SET_INITIALMARKETVALUATIONLOADED(state, payload) {
      state.initialMarketValuationLoaded = payload
    },
    SET_CURRENTMARKETVALUATION(state, payload) {
      state.currentMarketValuation = payload
    },
  },
  actions: {
    quitSimulation: function ({ commit, dispatch }) {
      commit('transparisation/RESET', null, { root: true })
      commit('solvency/parameters/RESET', null, { root: true })
      commit('esg/parameters/RESET', null, { root: true })
      commit('risk/parameters/RESET', null, { root: true })
      commit('solvency/results/RESET', null, { root: true })
      commit('esg/results/RESET', null, { root: true })
      commit('risk/results/RESET', null, { root: true })
      commit('simulation/RESET_SIMULATION', null, { root: true })
      if (router.currentRoute.path === '/') {
        commit('solvency/results/SET_SCRREADY', true, { root: true })
      } else if (router.currentRoute.path.startsWith('/transparisation') || router.currentRoute.path.startsWith('/compute') || (router.currentRoute.path.startsWith('/solvency') && !router.currentRoute.path.includes('archives')) || (router.currentRoute.path.startsWith('/esg') && !router.currentRoute.path.includes('archives'))) {
        router.push('/')
      }
    },
    createSimulationFromPerimeter: function ({ commit, dispatch }) {
      commit('simulation/SET_SIMULATIONIDLOADED', false, { root: true })
      var url = this.state.baseUrl + '/api/simulation/positions'
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.post(url,
          {
            valuationDate: this.state.perimeter.valuationDate.value,
            rolename: this.state.perimeter.client.value.code,
            portfolios: this.state.perimeter.portfolios.value.map(e => e.code),
            compartments: this.state.perimeter.compartments.value.map(e => e.code),
            lookThroughLevel: this.state.perimeter.transparencyLevel.value.code,
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('simulation/SET_SIMULATIONID', response.data, { root: true })
          commit('simulation/SET_SIMULATIONIDLOADED', true, { root: true })
          commit('errors/SET_CREATINGSIMULATIONID_ERROR', false, { root: true })
          dispatch('simulation/getCashs', null, { root: true })
          dispatch('simulation/getCurrentMarketValuation', null, { root: true })
          dispatch('simulation/getInitialMarketValuation', null, { root: true })
          commit('solvency/results/SET_SCRDONE', false, { root: true })
          commit('esg/results/SET_ESG_DONE', false, { root: true })
          commit('risk/results/SET_RISK_DONE', false, { root: true })
        })
          .catch(() => {
            commit('simulation/SET_SIMULATIONID', null, { root: true })
            commit('errors/SET_CREATINGSIMULATIONID_ERROR', true, { root: true })
            commit('simulation/SET_SIMULATIONIDLOADED', false, { root: true })
          })
      })
    },
    computeSaveHierarchicalPositions: function ({ commit, dispatch }) {
      commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', true, { root: true })
      var url = this.state.baseUrl + '/api/simulation/computation'
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(url,
          {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            simulationID: this.state.simulation.simulationID,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        )
          .then(response => {
            commit('simulation/SET_PERIMETERID', response.data.perimeter.perimeterID, { root: true })
            commit('simulation/SET_TRANSPARISED', true, { root: true })
            commit('errors/SET_TRANSPARISATIONSIMULATION_ERROR', false, { root: true })
            commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', false, { root: true })
            dispatch('transparisation/getTotalMarketValuation', null, { root: true })
            dispatch('solvency/parameters/get_current_parameters', null, { root: true })
            dispatch('solvency/parameters/get_only_parameters', null, { root: true })
            dispatch('esg/parameters/getParameters', null, { root: true })
          })
          .catch(() => {
            commit('simulation/SET_PERIMETERID', null, { root: true })
            commit('simulation/SET_TRANSPARISED', false, { root: true })
            commit('errors/SET_TRANSPARISATIONSIMULATION_ERROR', true, { root: true })
            commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', false, { root: true })
          })
      })
    },
    getCashs: function ({ commit }) {
      commit('simulation/SET_CASHSLOADED', false, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/simulation/cash/',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
          params: {
            simulationID: this.state.simulation.simulationID,
          },
        })
          .then((response) => {
            commit('simulation/SET_CASHS', response.data, { root: true })
            commit('errors/SET_CASHSLOADING_ERROR', false, { root: true })
            commit('simulation/SET_CASHSLOADED', true, { root: true })
          })
          .catch(() => {
            commit('simulation/SET_CASHS', [], { root: true })
            commit('errors/SET_CASHSLOADING_ERROR', true, { root: true })
            commit('simulation/SET_CASHSLOADED', false, { root: true })
          })
      })
    },
    getCurrentMarketValuation: function ({ commit }) {
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/simulation/global-market-valuation/',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
          params: {
            simulationID: this.state.simulation.simulationID,
          },
        })
          .then((response) => {
            commit('simulation/SET_CURRENTMARKETVALUATION', response.data, { root: true })
            commit('errors/SET_CURRENTMARKETVALUATIONLOADING_ERROR', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CURRENTMARKETVALUATIONLOADING_ERROR', true, { root: true })
          })
      })
    },
    getInitialMarketValuation: function ({ commit, dispatch }) {
      commit('simulation/SET_INITIALMARKETVALUATIONLOADED', false, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/simulation/global-market-valuation/',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
          params: {
            simulationID: this.state.simulation.simulationID,
          },
        })
          .then((response) => {
            commit('simulation/SET_INITIALMARKETVALUATION', response.data, { root: true })
            commit('errors/SET_INITIALMARKETVALUATIONLOADING_ERROR', false, { root: true })
            dispatch('transparisation/getTotalMarketValuation', null, { root: true })
            commit('simulation/SET_INITIALMARKETVALUATIONLOADED', true, { root: true })
          })
          .catch(() => {
            commit('errors/SET_INITIALMARKETVALUATIONLOADING_ERROR', true, { root: true })
            commit('simulation/SET_INITIALMARKETVALUATIONLOADED', false, { root: true })
          })
      })
    },
  },
}
