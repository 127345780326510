export default {
  namespaced: true,
  state: {
    archiveRiskExists: false,
    globalRiskLiquidity: undefined,
    riskDone: false,
    riskLoading: false,
    computingRisk: false,
    onSimulation: false,
    perimeter: undefined,
  },
  getters: {
    riskDone: state => {
      return state.riskDone
    },
    riskLoading: state => {
      return state.riskLoading
    },
    computingRisk: state => {
      return state.computingRisk
    },
    archiveRiskExists: state => {
      return state.archiveRiskExists
    },
    globalRiskLiquidity: state => {
      return state.globalRiskLiquidity
    },
    perimeter: state => {
      return state.perimeter
    },
  },
  mutations: {
    RESET (state) {
      state.riskDone = false
      state.riskLoading = false
      state.computingRisk = false
      state.archiveRiskExists = false
      state.globalRiskLiquidity = undefined
      state.onSimulation = false
      state.perimeter = undefined
    },
    SET_RISK_DONE (state, payload) {
      state.riskDone = payload
    },
    SET_RISK_LOADING (state, payload) {
      state.riskLoading = payload
    },
    SET_RESULTS (state, payload) {
      state.globalRiskLiquidity = payload.globalRiskLiquidity
      state.perimeter = payload.perimeter
      if (payload) {
        state.onSimulation = payload.simulationID !== null
      }
    },
    SET_COMPUTINGRISK (state, payload) {
      state.computingRisk = payload
    },
    SET_ARCHIVERISKEXISTS (state, payload) {
      state.archiveRiskExists = payload
    },
  },
  actions: {
  },
}
