export default {
  namespaced: true,
  state: {
    computingEsg: false,
    results: null,
    done: false,
    loading: false,
    onSimulation: false,
    archiveEsgExists: false,
  },
  getters: {
    results: state => {
      return state.results.globalESGData
    },
    esgDone: state => {
      return state.done
    },
    esgLoading: state => {
      return state.loading
    },
    computingEsg: state => {
      return state.computingEsg
    },
    archiveEsgExists: state => {
      return state.archiveEsgExists
    },
  },
  mutations: {
    RESET: function (state) {
      state.computingEsg = false
      state.results = null
      state.done = false
      state.loading = false
      state.onSimulation = false
      state.archiveEsgExists = false
    },
    SET_RESULTS: function (state, payload) {
      state.results = payload
      if (payload !== undefined) {
        state.onSimulation = payload.simulationID !== null
      }
    },
    SET_ARCHIVEESGEXISTS (state, payload) {
      state.archiveEsgExists = payload
    },
    SET_ESG_DONE: function (state, payload) {
      state.done = payload
    },
    SET_ESG_LOADING: function (state, payload) {
      state.loading = payload
    },
    SET_COMPUTINGESG (state, payload) {
      state.computingEsg = payload
    },
  },
  actions: {

  },
}
