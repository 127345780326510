import Axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    files: [],
    loading: false,
    sharepointUrl: 'https://financieredelacite2.sharepoint.com/sites/Arago',
  },
  getters: {
    files: state => {
      return state.files
    },
    loading: state => {
      return state.loading
    },
  },
  mutations: {
    RESET (state) {
      state.files = []
      state.loading = false
      state.sharepointUrl = 'https://financieredelacite2.sharepoint.com/sites/Arago'
    },
    SET_FILES_LOADING: function (state, payload) {
      state.loading = payload
    },
    SET_FILES: function (state, payload) {
      payload.sort(function (a, b) {
        return new Date(b.Created) - new Date(a.Created)
      })
      state.files = []
      state.files = payload
    },
  },
  actions: {
    getSharepointFiles: function ({ commit }) {
      commit('integration/SET_FILES', [], { root: true })
      commit('SET_FILES_LOADING', true)
      var rolename = this.state.perimeter.client.value.code
      Vue.prototype.$AuthService
        .getAxiosTokenConfig()
        .then(config => {
          Axios({
            url: this.state.baseUrl + '/api/microsoft-tools/token',
            method: 'GET',
            headers: {
              authorization: config.headers.authorization,
            },
            params: {
              tokenType: 'Sharepoint',
            },
          }).then(response => {
            return Axios({
              url: this.state.news.sharepointUrl + '/_api/lists/getbytitle(%27Intégration%27)/items?$select=Title,Categorie,Etat,Expediteur,Created,NombreLignes,RoleDuSI/Rolename&$expand=RoleDuSI&$filter=RoleDuSI/Rolename eq \'' + rolename + '\'&$top=10000',
              method: 'GET',
              headers: {
                authorization: 'Bearer ' + response.data,
                Accept: 'application/json;odata=verbose',
              },
            })
              .then((res) => {
                commit('integration/SET_FILES', res.data.d.results, { root: true })
                commit('SET_FILES_LOADING', false)
                commit('errors/SET_LOADINGFILES_ERROR', false, { root: true })
              })
              .catch(() => {
                commit('SET_FILES_LOADING', false)
                commit('integration/SET_FILES', [], { root: true })
                commit('errors/SET_LOADINGFILES_ERROR', true, { root: true })
              })
          })
          .catch(() => {
            commit('integration/SET_FILES', [], { root: true })
            commit('SET_FILES_LOADING', false)
            commit('errors/SET_LOADINGFILES_ERROR', true, { root: true })
          })
        })
    },
  },
}
