import Axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    parametersChanged: false,
    parametersSaving: false,
    delta: 0.0001,
    piliers: {
      changed: false,
      items: [],
    },
    themes: {
      changed: false,
      itemsE: [],
      itemsS: [],
      itemsG: [],
    },
    allThemes: [],
    editingSector: '*',
    sectors: [],
    differentSectors: [],
    notations: {
      label: 'Notations forcées',
      value: [],
      saved: true,
      changed: false,
      loading: false,
      loaded: false,
    },
  },
  getters: {
    parametersChanged: state => {
      return state.parametersChanged
    },
    piliers: state => {
      return state.piliers
    },
    themes: state => {
      return state.themes
    },
    editingSector: state => {
      return state.editingSector
    },
    sectors: state => {
      return state.sectors
    },
    orderedSectors: (state, getters) => {
      var sec = [state.sectors[0]]
      for (var i = 1; i < state.sectors.length; i++) {
        var item = state.sectors[i]
        if (getters.differentSectors.filter(e => e.codificationID === item.codificationID).length > 0 && item.codificationID !== '*') {
          sec.push(item)
        }
      }
      for (i = 1; i < state.sectors.length; i++) {
        item = state.sectors[i]
        if (getters.differentSectors.filter(e => e.codificationID === item.codificationID).length === 0) {
          sec.push(item)
        }
      }
      return sec
    },
    differentSectors: state => {
      return state.differentSectors
    },
    notations: state => {
      return state.notations
    },
    parametersChangedUnsaved: state => {
      return state.piliers.changed || state.themes.changed || state.notations.changed
    },
    piliersSumValid: state => {
      return Math.abs(1 - state.piliers.items.map(item => parseFloat(item.ratios)).reduce((prev, curr) => prev + curr, 0)) < state.delta
    },
    themesESumValid: state => {
      return Math.abs(1 - state.themes.itemsE.map(item => parseFloat(item.ratios)).reduce((prev, curr) => prev + curr, 0)) < state.delta
    },
    themesSSumValid: state => {
      return Math.abs(1 - state.themes.itemsS.map(item => parseFloat(item.ratios)).reduce((prev, curr) => prev + curr, 0)) < state.delta
    },
    themesGSumValid: state => {
      return Math.abs(1 - state.themes.itemsG.map(item => parseFloat(item.ratios)).reduce((prev, curr) => prev + curr, 0)) < state.delta
    },
    piliersValid: (state, getters) => {
      return getters.piliersSumValid
    },
    themesValid: (state, getters) => {
      return getters.themesESumValid && getters.themesGSumValid && getters.themesSSumValid
    },
    piliersInvalidMessage: (state, getters) => {
      return 'La somme des paramètres doit être égale à 1.'
    },
    themesInvalidMessage: (state, getters) => {
      return 'La somme des paramètres doit être égale à 1.'
    },
    showResetSectorButton: state => {
      // return state.editingSector !== '*' && state.differentSectors.findIndex(e => e.codificationID === state.editingSector) !== -1
      // show if current form values are different from *
      var defaultSector = state.allThemes.filter(e => e.codificationID === '*')
      for (var thE of state.themes.itemsE) {
        if (thE.ratios !== defaultSector.find(f => f.themeID === thE.themeID && f.pilierID === thE.pilierID).ratios) {
          return true
        }
      }
      for (var thS of state.themes.itemsS) {
        if (thS.ratios !== defaultSector.find(f => f.themeID === thS.themeID && f.pilierID === thS.pilierID).ratios) {
          return true
        }
      }
      for (var thG of state.themes.itemsG) {
        if (thG.ratios !== defaultSector.find(f => f.themeID === thG.themeID && f.pilierID === thG.pilierID).ratios) {
          return true
        }
      }
      return false
    },
  },
  mutations: {
    RESET(state) {
      state.parametersChanged = false
      state.parametersSaving = false
      state.delta = 0.0001
      state.piliers = {
        changed: false,
        items: [],
      }
      state.themes = {
        changed: false,
        itemsE: [],
        itemsS: [],
        itemsG: [],
      }
      state.editingSector = '*'
      state.allThemes = []
      state.sectors = []
      state.differentSectors = []
      state.notations = {
        label: 'Notations forcées',
        value: [],
        saved: true,
        changed: false,
        loading: false,
        loaded: false,
      }
    },
    SET_PARAMETERSCHANGED: function (state, payload) {
      state.parametersChanged = payload
    },
    SET_PARAMETERSSAVING: function (state, payload) {
      state.parametersSaving = payload
    },
    SET_PILIERS: function (state, payload) {
      state.piliers.items = [payload.find(e => e.pilierID === 'E'), payload.find(e => e.pilierID === 'S'), payload.find(e => e.pilierID === 'G')]
    },
    SET_PILIERS_CHANGED: function (state, payload) {
      state.piliers.changed = payload
    },
    SET_DEFAULT_THEMES: function (state) {
      state.themes.itemsE = state.allThemes.filter(e => e.pilierID === 'E' && e.codificationID === '*')
      state.themes.itemsS = state.allThemes.filter(e => e.pilierID === 'S' && e.codificationID === '*')
      state.themes.itemsG = state.allThemes.filter(e => e.pilierID === 'G' && e.codificationID === '*')
    },
    SET_SECTOR_THEMES: function (state) {
      state.themes.itemsE = state.allThemes.filter(e => e.pilierID === 'E' && e.codificationID === state.editingSector)
      if (state.themes.itemsE.length === 0) {
        state.themes.itemsE = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'E' && e.codificationID === '*')))
        for (var th of state.themes.itemsE) {
          th.codificationID = state.editingSector
        }
      }
      state.themes.itemsS = state.allThemes.filter(e => e.pilierID === 'S' && e.codificationID === state.editingSector)
      if (state.themes.itemsS.length === 0) {
        state.themes.itemsS = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'S' && e.codificationID === '*')))
        for (th of state.themes.itemsS) {
          th.codificationID = state.editingSector
        }
      }
      state.themes.itemsG = state.allThemes.filter(e => e.pilierID === 'G' && e.codificationID === state.editingSector)
      if (state.themes.itemsG.length === 0) {
        state.themes.itemsG = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'G' && e.codificationID === '*')))
        for (th of state.themes.itemsG) {
          th.codificationID = state.editingSector
        }
      }
    },
    SET_THEMES_CHANGED: function (state, payload) {
      state.themes.changed = payload
    },
    SET_ALL_THEMES: function (state, payload) {
      state.allThemes = payload
    },
    SET_SECTORS(state, payload) {
      state.sectors = [{
        codificationType: 'CodeNaceDivision',
        codificationID: '*',
        libelleFR: 'Par défaut',
        libelleEN: 'Default',
      }].concat(payload.sort(function (a, b) {
        var nameA = a.libelleFR.toLowerCase()
        var nameB = b.libelleFR.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }))
      this.commit('esg/parameters/SET_DIFFERENTSECTORS', null, { root: true })
    },
    SET_DIFFERENTSECTORS(state) {
      var codes = state.allThemes.map(e => e.codificationID)
      var s = state.sectors.filter(e => codes.findIndex(f => f === e.codificationID) !== -1)
      state.differentSectors = s
    },
    SET_EDITINGSECTOR(state, payload) {
      state.editingSector = payload
      this.commit('esg/parameters/SET_SECTOR_THEMES', null, { root: true })
      this.commit('esg/parameters/SET_THEMES_CHANGED', false, { root: true })
    },
    RESET_SECTOR_THEMES(state) {
      state.themes.itemsE = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'E' && e.codificationID === '*')))
      for (var th of state.themes.itemsE) {
        th.codificationID = state.editingSector
      }
      state.themes.itemsS = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'S' && e.codificationID === '*')))
      for (th of state.themes.itemsS) {
        th.codificationID = state.editingSector
      }
      state.themes.itemsG = JSON.parse(JSON.stringify(state.allThemes.filter(e => e.pilierID === 'G' && e.codificationID === '*')))
      for (th of state.themes.itemsG) {
        th.codificationID = state.editingSector
      }
      var ixToDelete = state.differentSectors.findIndex(e => e.codificationID === state.editingSector)
      state.differentSectors.splice(ixToDelete, 1)
    },
    SET_NOTATIONS(state, payload) {
      state.notations.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.notations.value = p
      for (i = 0; i < state.notations.value.length; i++) {
        var ps = state.notations.value[i]
        if (ps.cic === undefined) {
          state.notations.value[i].cic = {
            code: '*',
            mainCategory: 'Non défini',
            subCategory: 'Non défini',
          }
        }
        this.dispatch('esg/parameters/isNotationsInPerimeter', ps, { root: true })
      }
      state.notations.loaded = true
    },
    SET_NOTATIONS_CHANGED(state, payload) {
      state.notations.changed = payload
      this.commit('esg/parameters/SET_PARAMETERSCHANGED', true, { root: true })
    },
    ADD_NOTATIONS(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.notations.value.push(payload[i])
      }
    },
    REMOVE_NOTATIONS(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.notations.value.findIndex(e => e.code === payload[i].code)
        state.notations.value.splice(ix, 1)
      }
    },
    SET_NOTATIONS_NOTE(state, payload) {
      var ix = state.notations.value.findIndex(e => e.code === payload.code)
      state.notations.value[ix].note = payload.note
    },
    SET_NOTATIONS_INPERIMETER(state, payload) {
      state.notations.value.find(e => e.code === payload.ps.code).inPerimeter = payload.inPerimeter
      state.notations.nbInPerimSet = state.notations.nbInPerimSet + 1
    },
    SET_NOTATIONS_LOADING(state, payload) {
      state.notations.loading = payload
    },
  },
  actions: {
    getParameters: function ({ dispatch }) {
      dispatch('esg/parameters/getPiliers', null, { root: true })
      dispatch('esg/parameters/getThemes', null, { root: true })
      dispatch('esg/parameters/getSectors', null, { root: true })
      dispatch('esg/parameters/load_notations', null, { root: true })
    },
    getSectors: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/esg/sectors',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('esg/parameters/SET_SECTORS', response.data, { root: true })
            commit('errors/SET_GETSECTORS_ERROR', false, { root: true })
          })
          .catch(() => {
            commit('esg/parameters/SET_SECTORS', [], { root: true })
            commit('errors/SET_GETSECTORS_ERROR', true, { root: true })
          })
      })
    },
    getPiliers: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/esg/param-piliers',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('esg/parameters/SET_PILIERS', response.data, { root: true })
            commit('esg/parameters/SET_PILIERS_CHANGED', false, { root: true })
            commit('errors/SET_GETPILIERS_ERROR', false, { root: true })
          })
          .catch(() => {
            commit('esg/parameters/SET_PILIERS', [], { root: true })
            commit('esg/parameters/SET_PILIERS_CHANGED', false, { root: true })
            commit('errors/SET_GETPILIERS_ERROR', true, { root: true })
          })
      })
    },
    getThemes: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/esg/param-themes',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            // codificationType: 'CodeNaceDivision',
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('esg/parameters/SET_ALL_THEMES', response.data, { root: true })
            commit('esg/parameters/SET_SECTOR_THEMES', null, { root: true })
            commit('esg/parameters/SET_THEMES_CHANGED', false, { root: true })
            commit('errors/SET_GETTHEMES_ERROR', false, { root: true })
            commit('esg/parameters/SET_DIFFERENTSECTORS', null, { root: true })
          })
          .catch(() => {
            commit('esg/parameters/SET_ALL_THEMES', [], { root: true })
            commit('esg/parameters/SET_THEMES_CHANGED', false, { root: true })
            commit('errors/SET_GETTHEMES_ERROR', true, { root: true })
          })
      })
    },
    setPiliers: function ({ commit }) {
      commit('esg/parameters/SET_PARAMETERSSAVING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/esg/param-piliers',
          {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            piliers: this.state.esg.parameters.piliers.items,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('esg/parameters/SET_PARAMETERSCHANGED', true, { root: true })
          commit('errors/SET_SETPILIERS_ERROR', false, { root: true })
          commit('esg/parameters/SET_PILIERS_CHANGED', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_SETPILIERS_ERROR', true, { root: true })
            commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },
    isThemeLikeDefault: function ({ commit }, payload) {
      if (payload.codificationID === '*') {
        return { codificationID: payload.codificationID, themeLikeDefault: false }
      }
      var defaultTh = payload.all.filter(e => e.codificationID === '*')
      var sec = payload.all.filter(f => f.codificationID === payload.codificationID)
      for (var j = 0; j < sec.length; j++) {
        var equivDef = defaultTh.filter(h => h.pilierID === sec[j].pilierID && h.themeID === sec[j].themeID)
        if (equivDef[0].ratios !== sec[j].ratios) {
          return { codificationID: payload.codificationID, themeLikeDefault: false }
        }
      }
      return { codificationID: payload.codificationID, themeLikeDefault: true }
    },
    async setThemes({ commit, dispatch }) {
      commit('esg/parameters/SET_PARAMETERSSAVING', true, { root: true })
      var t = this.state.esg.parameters.allThemes.filter(e => e.codificationID !== this.state.esg.parameters.editingSector).concat(this.state.esg.parameters.themes.itemsE, this.state.esg.parameters.themes.itemsS, this.state.esg.parameters.themes.itemsG)
      var secs = [...new Set(t.map(item => item.codificationID))]
      var secsInfo = []
      for (var i = 0; i < secs.length; i++) {
        var s = secs[i]
        await dispatch('esg/parameters/isThemeLikeDefault', { codificationID: s, all: t }, { root: true }).then(response => {
          if (response.themeLikeDefault) {
            secsInfo.push(response.codificationID)
          }
        })
      }
      t = t.filter(e => secsInfo.findIndex(f => f === e.codificationID) === -1)
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/esg/param-themes',
          {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            roleName: this.state.perimeter.client.value.code,
            themes: t,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('esg/parameters/SET_PARAMETERSCHANGED', true, { root: true })
          commit('errors/SET_SETTHEMES_ERROR', false, { root: true })
          commit('esg/parameters/SET_THEMES_CHANGED', false, { root: true })
          dispatch('esg/parameters/getThemes', null, { root: true })
        })
          .catch(() => {
            commit('errors/SET_SETTHEMES_ERROR', true, { root: true })
            commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },
    load_notations: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/esg/note-exceptions',
          method: 'GET',
          params: {
            rolename: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('esg/parameters/SET_NOTATIONS', response.data, { root: true })
            commit('errors/SET_NOTATIONS_ERROR_LOADING', false, { root: true })
            commit('esg/parameters/SET_NOTATIONS_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_NOTATIONS_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isNotationsInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('esg/parameters/SET_NOTATIONS_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    saveNotationsFromValue: function ({ commit }) {
      commit('esg/parameters/SET_NOTATIONS_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.esg.parameters.notations.value.length; i++) {
        var el = this.state.esg.parameters.notations.value[i]
        tosend.push({
          rolename: this.state.perimeter.client.value.code,
          code: el.code,
          denomination: el.denomination,
          codeCIC: el.cic.code,
          note: el.note,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/esg/note-exceptions',
          {
            rolename: this.state.perimeter.client.value.code,
            noteExceptions: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('esg/parameters/SET_PARAMETERSCHANGED', true, { root: true })
          commit('errors/SET_NOTATIONS_ERROR_SAVINGVALUE', false, { root: true })
          commit('esg/parameters/SET_NOTATIONS_CHANGED', false, { root: true })
          // commit('esg/results/SET_SHOWGREENTICK', false, { root: true })
          commit('esg/parameters/SET_NOTATIONS_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_NOTATIONS_ERROR_SAVINGVALUE', true, { root: true })
            this.dispatch('esg/parameters/load_notations', null, { root: true })
            commit('esg/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('esg/parameters/SET_NOTATIONS_LOADING', false, { root: true })
          })
      })
    },
  },
}
