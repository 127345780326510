import Vue from 'vue'
import Router from 'vue-router'
import AuthService from './msal'
import store from './store'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.prototype.$AuthService = new AuthService()

Vue.use(Router)

function guard(to, from, next) {
  if (Vue.prototype.$AuthService.isAuthenticated()) {
    Vue.prototype.$AuthService
      .isTransparencyMember()
      .then((result) => {
        window.dataLayer = window.dataLayer || []
        const r = Vue.prototype.$AuthService.getAccount().accountIdentifier;
        window.dataLayer.push({
          userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
        })

        store.commit('SET_HASTRANSPARENCYRIGHTS', result)
        if (result) {
          store.commit('SET_USER_ACCOUNT')
          // set solvency rights
          Vue.prototype.$AuthService
            .isSolvencyMember()
            .then((solv) => {
              store.commit('SET_HASSOLVENCYRIGHTS', solv)
            })
            .catch(() => {
              Vue.prototype.$AuthService.logout()
            })
          // set simulation rights
          Vue.prototype.$AuthService
            .isSimulationMember()
            .then((solv) => {
              store.commit('SET_HASSIMULATIONRIGHTS', solv)
            })
            .catch(() => {
              Vue.prototype.$AuthService.logout()
            })
          // set esg rights
          Vue.prototype.$AuthService
            .isEsgMember()
            .then((solv) => {
              store.commit('SET_HASESGRIGHTS', solv)
            })
            .catch(() => {
              Vue.prototype.$AuthService.logout()
            })
          // set risk rights
          Vue.prototype.$AuthService
            .isRiskMember()
            .then((solv) => {
              store.commit('SET_HASRISKRIGHTS', solv)
            })
            .catch(() => {
              Vue.prototype.$AuthService.logout()
            })
          next()
        } else {
          Vue.prototype.$AuthService.logout()
        }
      })
      .catch(() => {
        Vue.prototype.$AuthService.logout()
      })
  } else {
    if (store.getters.resetLogin) {
      return
    }
    Vue.prototype.$AuthService.login()
  }
}

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/arago-reports',
      component: () => import('@/layouts/general/RawIndex'),
      children: [
        {
          name: 'Indicateurs Transparisés Full Page',
          path: 'transparisation/reporting',
          component: () => import('@/views/transpa/reporting/Reporting'),
        },
        {
          name: 'Détails SCR Full Page',
          path: 'solvency/archives/details',
          component: () => import('@/views/solvency/archives/DetailsReport'),
        },
        {
          name: 'Historique SCR Full Page',
          path: 'solvency/archives/historical',
          component: () => import('@/views/solvency/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison SCR Full Page',
          path: 'solvency/archives/comparison',
          component: () => import('@/views/solvency/archives/ComparisonReport'),
        },
        {
          name: 'Détails ESG Full Page',
          path: 'esg/archives/details',
          component: () => import('@/views/esg/archives/DetailsReport'),
        },
        {
          name: 'Historique ESG Full Page',
          path: 'esg/archives/historical',
          component: () => import('@/views/esg/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison ESG Full Page',
          path: 'esg/archives/comparison',
          component: () => import('@/views/esg/archives/ComparisonReport'),
        },
        {
          name: 'Température ESG Full Page',
          path: 'esg/archives/temperature',
          component: () => import('@/views/esg/archives/TemperatureReport'),
        },
        {
          name: 'Détails Risque Full Page',
          path: 'risk/archives/details',
          component: () => import('@/views/risk/archives/DetailsReport'),
        },
        {
          name: 'Détails Transpa Full Page',
          path: 'transparisation/archives/details',
          component: () => import('@/views/transpa/archives/DetailsReport'),
        },
        {
          name: 'Historique Transpa Full Page',
          path: 'transparisation/archives/historical',
          component: () => import('@/views/transpa/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison Transpa Full Page',
          path: 'transparisation/archives/comparison',
          component: () => import('@/views/transpa/archives/ComparisonReport'),
        },
      ],
    },

    {
      path: '/',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Arago',
          path: '',
          component: () => import('@/views/general/home/HomePage'),
        },
        {
          name: 'Intégration',
          path: '/integration',
          component: () => import('@/views/general/integration/IntegrationPage'),
        },
        {
          name: 'Calculs',
          path: '/compute',
          component: () => import('@/views/general/compute/ComputePage'),
        },
        {
          name: 'Gestion des sauvegardes',
          path: '/archives',
          component: () => import('@/views/general/archives/ArchivesTablePage'),
        },
        {
          name: 'Aide',
          path: '/help',
          component: () => import('@/views/general/help/HelpPage'),
        },
      ],
    },
    {
      path: '/auth',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/general/home/HomePage'),
          beforeEnter: (to, from, next) => {
            Vue.prototype.$AuthService.logout()
          },
        },
      ],
    },
    {
      path: '/transparisation',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Portefeuille Transparisé',
          path: 'portfolio',
          component: () => import('@/views/transpa/inventory/InventoryPage'),
        },
        {
          name: 'Indicateurs Transparisés',
          path: 'reporting',
          component: () => import('@/views/transpa/reporting/Reporting'),
        },
        {
          name: 'Détails Transparisation',
          path: 'archives/details',
          component: () => import('@/views/transpa/archives/DetailsReport'),
        },
        {
          name: 'Historique Transparisation',
          path: 'archives/historical',
          component: () => import('@/views/transpa/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison Transparisation',
          path: 'archives/comparison',
          component: () => import('@/views/transpa/archives/ComparisonReport'),
        },
      ],
    },
    {
      path: '/solvency',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Solvabilité II',
          path: 'home',
          component: () => import('@/views/solvency/home/HomePage'),
        },
        {
          name: 'Participations Stratégiques',
          path: 'clientdata/strategicparticipations',
          component: () => import('@/views/solvency/clientdata/StrategicParticipationsPage'),
        },
        {
          name: 'LTEI',
          path: 'clientdata/ltei',
          component: () => import('@/views/solvency/clientdata/LTEIPage'),
        },
        {
          name: 'Actifs Intra Groupe',
          path: 'clientdata/intragroup',
          component: () => import('@/views/solvency/clientdata/IntraGroupPage'),
        },
        {
          name: 'Exclusion SCR Concentration',
          path: 'clientdata/concentrationexclusion',
          component: () => import('@/views/solvency/clientdata/ConcentrationExclusionPage'),
        },
        {
          name: 'Taux d\'endettement',
          path: 'clientdata/propertyleverage',
          component: () => import('@/views/solvency/clientdata/PropertyLeveragePage'),
        },
        {
          name: 'SCR',
          path: 'results',
          component: () => import('@/views/solvency/results/ResultsDashboard'),
        },
        {
          name: 'Détails SCR',
          path: 'archives/details',
          component: () => import('@/views/solvency/archives/DetailsReport'),
        },
        {
          name: 'Historique SCR',
          path: 'archives/historical',
          component: () => import('@/views/solvency/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison SCR',
          path: 'archives/comparison',
          component: () => import('@/views/solvency/archives/ComparisonReport'),
        },
      ],
    },
    {
      path: '/esg',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Paramètres',
          path: 'parameters',
          component: () => import('@/views/esg/parameters/ESGParametersPage'),
        },
        {
          name: 'Notations Forcées',
          path: 'noteexceptions',
          component: () => import('@/views/esg/parameters/ESGForcedNotesPage'),
        },
        {
          name: 'Résultats',
          path: 'results',
          component: () => import('@/views/esg/results/ESGResultsPage'),
        },
        {
          name: 'Détails ESG',
          path: 'archives/details',
          component: () => import('@/views/esg/archives/DetailsReport'),
        },
        {
          name: 'Historique ESG',
          path: 'archives/historical',
          component: () => import('@/views/esg/archives/HistoricalReport'),
        },
        {
          name: 'Comparaison ESG',
          path: 'archives/comparison',
          component: () => import('@/views/esg/archives/ComparisonReport'),
        },
        {
          name: 'Température ESG',
          path: 'archives/temperature',
          component: () => import('@/views/esg/archives/TemperatureReport'),
        },
      ],
    },
    {
      path: '/risk',
      component: () => import('@/layouts/general/Index'),
      children: [
        {
          name: 'Scénarios Liquidité',
          path: 'parameters',
          component: () => import('@/views/risk/parameters/ParametersPage'),
        },
        {
          name: 'Résultats Risque',
          path: 'results',
          component: () => import('@/views/risk/results/ResultsPage'),
        },
        {
          name: 'Détails Risque',
          path: 'archives/details',
          component: () => import('@/views/risk/archives/DetailsReport'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

Vue.use(VueGtm, {
  // id: 'GTM-KZM7D5Q', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  id: 'GTM-W57QCM2', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: process.env.VUE_APP_MODE === 'prod', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
})

router.beforeEach((to, from, next) => {
  guard(to, from, next)
})

router.afterEach((to, from, next) => {
  store.commit('SET_MENUOPENED', false)
  if (from.path.includes('details')) {
    store.commit('archives/RESET_SELECTEDARCHIVES', undefined, { root: true })
  }
})
export default router
