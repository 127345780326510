<template>
  <v-list-group
    :value="value"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
  >
    <template
      v-if="item.icon"
      v-slot:prependIcon
    >
      <v-icon
        :class="{
          'disabled-text': disabled
        }"
        size="1.4rem"
      >
        {{ item.icon }}
      </v-icon>
    </template>
    <template v-slot:appendIcon>
      <v-icon
        :class="{
          'disabled-text': disabled
        }"
      >
        mdi-menu-down
      </v-icon>
    </template>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title
          :id="'item-group-' + mainMenu.replace(/\s/g, '') + '-' + item.title.replace(/\s/g, '')"
          :class="{'disabled-text': disabled}"
          class="text-body-1 font-weight-light"
        >
          {{ item.title }}
          <v-tooltip
            v-if="warning"
            bottom
            color="tooltip"
            max-width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  color="orange"
                  small
                  right
                >
                  mdi-alert-circle-outline
                </v-icon>
              </span>
            </template>
            <span>Des modifications on été effectuées dans le périmètre ou certains paramètres, les résultats ci-dessous n'en tiennent pas compte et reflètent le dernier calcul effectué.</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        :disabled="disabled || child.disabled"
        :main-menu=" mainMenu.replace(/\s/g, '') + '-' + item.title.replace(/\s/g, '')"
      />

      <base-item
        v-else
        :key="`item-${i}`"
        :item="child"
        text
        :disabled="disabledItem(child)"
        :warning="warning"
      />
    </template>
  </v-list-group>
</template>

<script>
  import kebabCase from 'lodash/kebabCase'
  import { mapState } from 'vuex'

  export default {
    name: 'ItemGroup',

    inheritAttrs: false,

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          icon: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
      subGroup: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      warning: {
        type: Boolean,
        default: false,
      },
      mainMenu: {
        type: String,
        default: 'arago',
      },
    },
    data () {
      return {
        value: false,
      }
    },
    computed: {
      ...mapState(['barColor']),
      children () {
        return this.item.children.map(item => ({
          ...item,
          to: !item.to ? undefined : `${this.item.group}/${item.to}`,
        }))
      },
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      group () {
        return this.genGroup(this.item.children)
      },
    },

    methods: {
      genGroup (children) {
        return children
          .filter(item => item.to)
          .map(item => {
            const parent = item.group || this.item.group
            let group = `${parent}/${kebabCase(item.to)}`

            if (item.children) {
              group = `${group}|${this.genGroup(item.children)}`
            }

            return group
          }).join('|')
      },
      disabledItem: function (menuItem) {
        return (menuItem.scrDependent && !this.$store.getters['solvency/results/scrDone']) ||
          (menuItem.esgDependent && !this.$store.getters['esg/results/esgDone']) ||
          (menuItem.riskDependent && !this.$store.getters['risk/results/riskDone']) ||
          this.$store.getters['solvency/parameters/parametersSaving'] ||
          this.$store.getters['solvency/results/scrLoading'] ||
          this.$store.getters['transparisation/inventoryLoading'] ||
          (menuItem.dataDependent && !this.$store.getters['transparisation/inventoryLoaded']) ||
          (menuItem.archiveSCRDependent && this.$store.getters['archives/noArchiveScr']) ||
          (menuItem.archiveESGDependent && this.$store.getters['archives/noArchiveEsg']) ||
          (menuItem.archiveRiskDependent && this.$store.getters['archives/noArchiveRisk']) ||
          (menuItem.rolenameDependent && this.$store.getters['perimeter/rolenameEmpty']) ||
          (menuItem.simulationTranspaDependent && this.$store.getters['simulation/simulationMode'] && !this.$store.getters['simulation/transparised'])
      },
    },
  }
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
.disabled-text {
  color: grey !important;
}
.v-list-group__items {
  border-left: 3px solid var(--v-detailsDark-base);
}
</style>
