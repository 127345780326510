import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)
const theme = {
    primary: '#A41D1B',
    secondary: '#5a271f', // '#87b4b5', // '#1BA2A4',
    accent: '#1BA2A4',
    info: '#2D3085',
    tooltip: '#000000',
    cancel: '#2b2626',
    detailsDark: '#4a4a4a',
    detailsLight: '#a7a7a7',
    success: '#74c174',
    warning: '#d08a48',
    label: '#d3d2d2',
    simulation: '#3bbbff',
    simulationYellow: '#ffda3b',
}

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
        options: {
            customProperties: true,
        },
    },
})
