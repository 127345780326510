import Axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    parametersChanged: false,
    parametersSaving: false,
    equity: {
      changed: false,
      longTermStress: {
        label: 'Choc actif long terme',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur de choc retenue pour les actifs définis comme étant des actifs de détention longue.',
      },
      partStratStress: {
        label: 'Choc participation stratégique',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur de choc retenue pour les actifs définis comme étant des Participations Stratégiques.',
      },
      type1Stress: {
        label: 'Choc type 1 (EEE/OCDE)',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur de choc retenue pour les actifs appartenant à l\'OCDE ou dans l\'EEE et soumis au SCR Action.',
      },
      type2Stress: {
        label: 'Choc type 2 (hors EEE/OCDE)',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur de choc retenue pour les actifs hors OCDE et EEE et soumis au SCR Action. Choc par défaut pour les actifs indéterminés.',
      },
      financialInstitutionInOwnFundTreshold: {
        label: 'Seuil maximum de détention d\'institutions financières',
        value: 0,
        saved: true,
        show: false,
        tooltip: '',
      },
      type1Type2Correlation: {
        label: 'Corrélation entre les chocs de types 1 et 2',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'La corrélation entre le choc de type 1 et celui de type 2 intervient dans le calcul du SCR Action Total.',
      },
      useTransitoryStress: {
        label: 'Utilisation de chocs de transition',
        value: false,
        saved: true,
        show: true,
        tooltip: 'Applique les mesures transitoires dans les calculs de SCR Action si activé.',
      },
      stressRange: {
        label: 'Intervalle de validité du Delta',
        value: 0,
        saved: true,
        show: false,
        tooltip: 'Valable pour les titres au niveau 0. Permet d\'avoir un delta identique sur l\'invertalle de choc suivant : [choc de calcul du Delta - cette valeur ; choc de calcul du Delta + cette valeur]',
      },
    },
    rate: {
      changed: false,
      yieldCurves: [],
      yieldCurveID: {
        label: 'Courbe des taux',
        value: undefined,
        saved: true,
        show: true,
        tooltip: 'Courbe des taux à utiliser dans le calcul des SCR Taux',
      },
      nominalYieldRealYieldCorrelation: {
        label: 'Corrélation taux nominal/taux réel',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Pour les obligations indéxées sur l\'inflation, cette corrélation est prise en compte dans le calcul de la courbe des taux choquée.',
      },
      loanDefaultSensi: {
        label: 'Sensibilité forfaitaire',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Sensibilité par défaut utilisée pour les actifs n\'ayant pas de sensibilité définie. ',
      },
      cashDefaultSensi: {
        label: 'Sensibilité forfaitaire de la trésorerie',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Sensibilité par défaut utilisée pour les actifs de type trésorerie n\'ayant pas de sensibilité définie. ',
      },
      minDeltaDown: {
        label: 'Variation minimum à la baisse',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Dans le scénario baisse des taux et hors ORSA, correspond à la variation minimale que subit la courbe des taux ',
      },
      minDeltaUp: {
        label: 'Variation minimum à la hausse',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Dans le scénario hausse des taux et hors ORSA, correspond à la variation minimale que subit la courbe des taux ',
      },
      isORSA: {
        label: 'ORSA',
        value: false,
        saved: true,
        show: true,
        tooltip: 'Applique les formules OSRA pour le calcul du SCR Taux si activé.',
      },
      computeMethod: {
        label: 'Méthode de calcul',
        value: 0,
        saved: true,
        show: false,
        tooltip: '',
      },
      isEIOPA2019: {
        label: 'EIOPA 2019',
        value: false,
        saved: true,
        show: false,
        tooltip: 'EIOPA 2019',
      },
      modifiedDurationPriority: {
        label: 'Duration prioritaire',
        value: '',
        saved: true,
        show: true,
        tooltip: 'Duration prioritaire',
      },
      durations: [],
    },
    property: {
      changed: false,
      propertyStress: {
        label: 'Choc immobilier',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur du choc retenue pour les actifs de type immobilier.',
      },
    },
    currency: {
      changed: false,
      currencyStress: {
        label: 'Choc',
        value: 0,
        saved: true,
        show: true,
        tooltip: 'Valeur du choc retenue pour les actifs dans une devise autre que la devise de référence.',
      },
      referenceCurrency: {
        label: 'Devise de référence',
        value: undefined,
        saved: true,
        show: true,
        tooltip: '',
      },
      exceptionStressByCurrencies: {
        label: 'Choc par devise',
        value: [], // list of {code (of currency), name (of currency), value (of stress)}
        saved: true,
        show: true,
        tooltip: '',
      },
      currencies: [],
      availableCurrencies: [],
    },
    scrReference: null,
    strategicParticipations: {
      label: 'Participations stratégiques',
      value: [],
      saved: true,
      changed: false,
      availableCIC: [],
      loading: false,
      loaded: false,
      loadedCIC: false,
    },
    ltei: {
      label: 'LTEI',
      value: [],
      saved: true,
      changed: false,
      availableCIC: [],
      loading: false,
      loaded: false,
      loadedCIC: false,
    },
    intraGroup: {
      label: 'Actifs intra groupe',
      value: [],
      saved: true,
      changed: false,
      loading: false,
      loaded: false,
    },
    concentrationExclusion: {
      label: 'Exclusion SCR concentration',
      value: [],
      saved: true,
      changed: false,
      loading: false,
      loaded: false,
    },
    propertyLeverage: {
      label: 'Effet de levier',
      value: [],
      saved: true,
      changed: false,
      availableCIC: [],
      loading: false,
      loaded: false,
      loadedCIC: false,
    },
  },
  getters: {
    parametersSaving: state => {
      return state.parametersSaving
    },
    parametersChanged: state => {
      return state.parametersChanged
    },
    parametersChangedUnsaved: state => {
      return state.equity.changed || state.rate.changed || state.property.changed || state.currency.changed || state.strategicParticipations.changed || state.ltei.changed || state.intraGroup.changed || state.concentrationExclusion.changed || state.propertyLeverage.changed
    },
    strategicParticipations: state => {
      return state.strategicParticipations
    },
    ltei: state => {
      return state.ltei
    },
    intraGroup: state => {
      return state.intraGroup
    },
    concentrationExclusion: state => {
      return state.concentrationExclusion
    },
    propertyLeverage: state => {
      return state.propertyLeverage
    },
    equity: state => {
      return state.equity
    },
    rate: state => {
      return state.rate
    },
    property: state => {
      return state.property
    },
    currency: state => {
      return state.currency
    },
    equityValid: state => {
      if (state.equity.longTermStress.show && (state.equity.longTermStress.value === undefined || state.equity.longTermStress.value === '')) {
        return false
      }
      if (state.equity.partStratStress.show && (state.equity.partStratStress.value === undefined || state.equity.partStratStress.value === '')) {
        return false
      }
      if (state.equity.type1Stress.show && (state.equity.type1Stress.value === undefined || state.equity.type1Stress.value === '')) {
        return false
      }
      if (state.equity.type2Stress.show && (state.equity.type2Stress.value === undefined || state.equity.type2Stress.value === '')) {
        return false
      }
      if (state.equity.financialInstitutionInOwnFundTreshold.show && (state.equity.financialInstitutionInOwnFundTreshold.value === undefined || state.equity.financialInstitutionInOwnFundTreshold.value === '')) {
        return false
      }
      if (state.equity.type1Type2Correlation.show && (state.equity.type1Type2Correlation.value === undefined || state.equity.type1Type2Correlation.value === '')) {
        return false
      }
      if (state.equity.stressRange.show && (state.equity.stressRange.value === undefined || state.equity.stressRange.value === '')) {
        return false
      }
      return true
    },
    rateValid: state => {
      if (state.rate.computeMethod.show && (state.rate.computeMethod.value === undefined || state.rate.computeMethod.value === '')) {
        return false
      }
      if (state.rate.nominalYieldRealYieldCorrelation.show && (state.rate.nominalYieldRealYieldCorrelation.value === undefined || state.rate.nominalYieldRealYieldCorrelation.value === '')) {
        return false
      }
      if (state.rate.yieldCurveID.show && (state.rate.yieldCurveID.value === undefined || state.rate.yieldCurveID.value === '')) {
        return false
      }
      if (state.rate.minDeltaDown.show && (state.rate.minDeltaDown.value === undefined || state.rate.minDeltaDown.value === '')) {
        return false
      }
      if (state.rate.minDeltaUp.show && (state.rate.minDeltaUp.value === undefined || state.rate.minDeltaUp.value === '')) {
        return false
      }
      if (state.rate.loanDefaultSensi.show && (state.rate.loanDefaultSensi.value === undefined || state.rate.loanDefaultSensi.value === '')) {
        return false
      }
      if (state.rate.cashDefaultSensi.show && (state.rate.cashDefaultSensi.value === undefined || state.rate.cashDefaultSensi.value === '')) {
        return false
      }
      return true
    },
    propertyValid: state => {
      if (state.property.propertyStress.show && (state.property.propertyStress.value === undefined || state.property.propertyStress.value === '')) {
        return false
      }
      return true
    },
    currencyValid: state => {
      if (state.currency.currencyStress.show && (state.currency.currencyStress.value === undefined || state.currency.currencyStress.value === '')) {
        return false
      }
      return true
    },
    parametersValid: (state, getters) => {
      return getters.equityValid && getters.rateValid && getters.propertyValid && getters.currencyValid
    },
    scrReference: state => {
      return state.scrReference
    },
  },
  mutations: {
    RESET(state) {
      state.parametersChanged = false
      state.parametersSaving = false
      state.equity = {
        changed: false,
        longTermStress: {
          label: 'Choc actif long terme',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur de choc retenue pour les actifs définis comme étant des actifs de détention longue.',
        },
        partStratStress: {
          label: 'Choc participation stratégique',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur de choc retenue pour les actifs définis comme étant des Participations Stratégiques.',
        },
        type1Stress: {
          label: 'Choc type 1 (EEE/OCDE)',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur de choc retenue pour les actifs appartenant à l\'OCDE ou dans l\'EEE et soumis au SCR Action.',
        },
        type2Stress: {
          label: 'Choc type 2 (hors EEE/OCDE)',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur de choc retenue pour les actifs hors OCDE et EEE et soumis au SCR Action. Choc par défaut pour les actifs indéterminés.',
        },
        financialInstitutionInOwnFundTreshold: {
          label: 'Seuil maximum de détention d\'institutions financières',
          value: 0,
          saved: true,
          show: false,
          tooltip: '',
        },
        type1Type2Correlation: {
          label: 'Corrélation entre les chocs de types 1 et 2',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'La corrélation entre le choc de type 1 et celui de type 2 intervient dans le calcul du SCR Action Total.',
        },
        useTransitoryStress: {
          label: 'Utilisation de chocs de transition',
          value: false,
          saved: true,
          show: true,
          tooltip: 'Applique les mesures transitoires dans les calculs de SCR Action si activé.',
        },
        stressRange: {
          label: 'Intervalle de validité du Delta',
          value: 0,
          saved: true,
          show: false,
          tooltip: 'Valable pour les titres au niveau 0. Permet d\'avoir un delta identique sur l\'invertalle de choc suivant : [choc de calcul du Delta - cette valeur ; choc de calcul du Delta + cette valeur]',
        },
      }
      state.rate = {
        changed: false,
        yieldCurves: [],
        yieldCurveID: {
          label: 'Courbe des taux',
          value: undefined,
          saved: true,
          show: true,
          tooltip: 'Courbe des taux à utiliser dans le calcul des SCR Taux',
        },
        nominalYieldRealYieldCorrelation: {
          label: 'Corrélation taux nominal/taux réel',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Pour les obligations indéxées sur l\'inflation, cette corrélation est prise en compte dans le calcul de la courbe des taux choquée.',
        },
        loanDefaultSensi: {
          label: 'Sensibilité forfaitaire',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Sensibilité par défaut utilisée pour les actifs n\'ayant pas de sensibilité définie. ',
        },
        cashDefaultSensi: {
          label: 'Sensibilité forfaitaire de la trésorerie',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Sensibilité par défaut utilisée pour les actifs de type trésorerie n\'ayant pas de sensibilité définie. ',
        },
        minDeltaDown: {
          label: 'Variation minimum à la baisse',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Dans le scénario baisse des taux et hors ORSA, correspond à la variation minimale que subit la courbe des taux ',
        },
        minDeltaUp: {
          label: 'Variation minimum à la hausse',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Dans le scénario hausse des taux et hors ORSA, correspond à la variation minimale que subit la courbe des taux ',
        },
        isORSA: {
          label: 'ORSA',
          value: false,
          saved: true,
          show: true,
          tooltip: 'Applique les formules OSRA pour le calcul du SCR Taux si activé.',
        },
        computeMethod: {
          label: 'Méthode de calcul',
          value: 0,
          saved: true,
          show: false,
          tooltip: '',
        },
        isEIOPA2019: {
          label: 'EIOPA 2019',
          value: false,
          saved: true,
          show: false,
          tooltip: 'EIOPA 2019',
        },
        modifiedDurationPriority: {
          label: 'Duration prioritaire',
          value: '',
          saved: true,
          show: true,
          tooltip: 'Duration prioritaire',
        },
        durations: [],
      }
      state.property = {
        changed: false,
        propertyStress: {
          label: 'Choc immobilier',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur du choc retenue pour les actifs de type immobilier.',
        },
      }
      state.currency = {
        changed: false,
        currencyStress: {
          label: 'Choc',
          value: 0,
          saved: true,
          show: true,
          tooltip: 'Valeur du choc retenue pour les actifs dans une devise autre que la devise de référence.',
        },
        referenceCurrency: {
          label: 'Devise de référence',
          value: undefined,
          saved: true,
          show: true,
          tooltip: '',
        },
        exceptionStressByCurrencies: {
          label: 'Choc par devise',
          value: [], // list of {code (of currency), name (of currency), value (of stress)}
          saved: true,
          show: true,
          tooltip: '',
        },
        currencies: [],
        availableCurrencies: [],
      }
      state.scrReference = null
      state.strategicParticipations = {
        label: 'Participations stratégiques',
        value: [],
        saved: true,
        changed: false,
        availableCIC: [],
        loading: false,
        loaded: false,
        loadedCIC: false,
        nbInPerimSet: 0,
      }
      state.ltei = {
        label: 'LTEI',
        value: [],
        saved: true,
        changed: false,
        availableCIC: [],
        loading: false,
        loaded: false,
        loadedCIC: false,
        nbInPerimSet: 0,
      }
      state.intraGroup = {
        label: 'Actifs intra groupe',
        value: [],
        saved: true,
        changed: false,
        loading: false,
        loaded: false,
        nbInPerimSet: 0,
      }
      state.concentrationExclusion = {
        label: 'Exclusion SCR concentration',
        value: [],
        saved: true,
        changed: false,
        loading: false,
        loaded: false,
        nbInPerimSet: 0,
      }
      state.propertyLeverage = {
        label: 'Effet de levier',
        value: [],
        saved: true,
        changed: false,
        availableCIC: [],
        loading: false,
        loaded: false,
        loadedCIC: false,
        nbInPerimSet: 0,
      }
    },
    SET_PARAMETERS_CHANGED(state, payload) {
      state.parametersChanged = payload
    },
    SET_EQUITY_CHANGED(state, payload) {
      state.equity.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    SET_RATE_CHANGED(state, payload) {
      state.rate.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    SET_PROPERTY_CHANGED(state, payload) {
      state.property.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    SET_CURRENCY_CHANGED(state, payload) {
      state.currency.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    SET_PARAMETERSSAVING(state, payload) {
      state.parametersSaving = payload
    },
    SET_EQUITY_PARAMETERS(state, payload) {
      state.equity.longTermStress.value = payload.longTermStress
      state.equity.partStratStress.value = payload.partStratStress
      state.equity.type1Stress.value = payload.type1Stress
      state.equity.type2Stress.value = payload.type2Stress
      state.equity.financialInstitutionInOwnFundTreshold.value = payload.financialInstitutionInOwnFundTreshold
      state.equity.type1Type2Correlation.value = payload.type1Type2Correlation
      state.equity.useTransitoryStress.value = payload.useTransitoryStress
      state.equity.stressRange.value = payload.stressRange

      state.equity.longTermStress.saved = true
      state.equity.partStratStress.saved = true
      state.equity.type1Stress.saved = true
      state.equity.type2Stress.saved = true
      state.equity.financialInstitutionInOwnFundTreshold.saved = true
      state.equity.type1Type2Correlation.saved = true
      state.equity.useTransitoryStress.saved = true
      state.equity.stressRange.saved = true
    },
    SET_RATE_YIELDCURVES(state, payload) {
      state.rate.yieldCurves = payload
    },
    SET_INTERESTRATE_PARAMETERS(state, payload) {
      state.rate.computeMethod.value = payload.computeMethod
      state.rate.nominalYieldRealYieldCorrelation.value = payload.nominalYieldRealYieldCorrelation
      state.rate.yieldCurveID.value = payload.yieldCurveID
      state.rate.minDeltaDown.value = payload.minDeltaDown
      state.rate.minDeltaUp.value = payload.minDeltaUp
      state.rate.loanDefaultSensi.value = payload.loanDefaultSensi
      state.rate.cashDefaultSensi.value = payload.cashDefaultSensi
      state.rate.isORSA.value = payload.isORSA
      state.rate.isEIOPA2019.value = payload.isEIOPA2019
      state.rate.modifiedDurationPriority.value = payload.modifiedDurationPriority

      state.rate.computeMethod.saved = true
      state.rate.nominalYieldRealYieldCorrelation.saved = true
      state.rate.yieldCurveID.saved = true
      state.rate.minDeltaDown.saved = true
      state.rate.minDeltaUp.saved = true
      state.rate.loanDefaultSensi.saved = true
      state.rate.cashDefaultSensi.saved = true
      state.rate.isORSA.saved = true
      state.rate.isEIOPA2019.saved = true
      state.rate.modifiedDurationPriority.saved = true
    },
    SET_DURATIONS(state, payload) {
      state.rate.durations = payload
    },
    SET_PROPERTY_PARAMETERS(state, payload) {
      state.property.propertyStress.value = payload.propertyStress
      state.property.propertyStress.saved = true
    },
    ADD_CURRENCY_EXCEPTIONSTRESSBYCURRENCY(state, payload) {
      var name = ''
      if (state.currency.availableCurrencies.find(e => e.code === payload.code) !== undefined) {
        name = state.currency.availableCurrencies.find(e => e.code === payload.code).name
      }
      var data = {
        code: payload.code,
        name: name,
        value: parseFloat(payload.value),
      }
      state.currency.exceptionStressByCurrencies.value.push(data)
      state.currency.exceptionStressByCurrencies.saved = false
      for (var i = 0; i < state.currency.availableCurrencies.length; i++) {
        if (state.currency.availableCurrencies[i].code === payload.code) {
          state.currency.availableCurrencies[i].disabled = true
          break
        }
      }
    },
    UPDATE_CURRENCY_EXCEPTIONSTRESSBYCURRENCY(state, payload) {
      for (var i = 0; i < state.currency.exceptionStressByCurrencies.value.length; i++) {
        if (state.currency.exceptionStressByCurrencies.value[i].code === payload.code) {
          state.currency.exceptionStressByCurrencies.value[i].stress = parseFloat(payload.value)
          break
        }
      }
      state.currency.exceptionStressByCurrencies.saved = false
    },
    REMOVE_CURRENCY_EXCEPTIONSTRESSBYCURRENCY(state, payload) {
      for (var i = 0; i < state.currency.exceptionStressByCurrencies.value.length; i++) {
        if (state.currency.exceptionStressByCurrencies.value[i].code === payload.code) {
          state.currency.exceptionStressByCurrencies.value.splice(i, 1)
          break
        }
      }
      for (var j = 0; j < state.currency.availableCurrencies.length; j++) {
        if (state.currency.availableCurrencies[j].code === payload.code) {
          state.currency.availableCurrencies[j].disabled = false
          break
        }
      }
      state.currency.exceptionStressByCurrencies.saved = false
    },
    SET_CURRENCY_CURRENCIES(state, payload) {
      state.currency.currencies = payload
    },
    SET_CURRENCY_AVAILABLECURRENCIES(state, payload) {
      state.currency.availableCurrencies = payload
      for (var i = 0; i < state.currency.availableCurrencies.length; i++) {
        state.currency.availableCurrencies[i].disabled = false
      }
    },
    SET_CURRENCY_PARAMETERS(state, payload) {
      this.dispatch('solvency/parameters/load_currencies', null, { root: true }).then(() => {
        // this.commit('solvency/parameters/SET_CURRENCY_CURRENCIES', response, { root: true })
        this.commit('solvency/parameters/SET_CURRENCY_AVAILABLECURRENCIES', state.currency.currencies, { root: true })
        state.currency.currencyStress.value = payload.currencyStress
        state.currency.referenceCurrency.value = state.currency.currencies.find(e => e.code === payload.referenceCurrency)
        for (var i = 0; i < state.currency.availableCurrencies.length; i++) {
          if (state.currency.availableCurrencies[i].code === payload.referenceCurrency) {
            state.currency.availableCurrencies[i].disabled = true
            break
          }
        }
        state.currency.exceptionStressByCurrencies.value = []
        if (payload.exceptionStressByCurrencies !== undefined) {
          for (var j = 0; j < Object.keys(payload.exceptionStressByCurrencies).length; j++) {
            this.commit('solvency/parameters/ADD_CURRENCY_EXCEPTIONSTRESSBYCURRENCY', {
              code: Object.keys(payload.exceptionStressByCurrencies)[j],
              name: state.currency.currencies.find(e => e.code === Object.keys(payload.exceptionStressByCurrencies)[j]),
              value: payload.exceptionStressByCurrencies[Object.keys(payload.exceptionStressByCurrencies)[j]],
            }, { root: true })
          }
        }
      })
      state.currency.currencyStress.saved = true
      state.currency.referenceCurrency.saved = true
      state.currency.exceptionStressByCurrencies.saved = true
    },
    SET_SCRREFERENCE(state, payload) {
      state.scrReference = payload
    },
    SET_PARAMETERS_TOSCRREFERENCE(state) {
      state.equity.type1Stress.value = state.scrReference.type1Stress
      state.equity.type1Stress.saved = false
      state.equity.type2Stress.value = state.scrReference.type2Stress
      state.equity.type2Stress.saved = false
      state.rate.yieldCurveID.value = state.scrReference.yieldCurve
      state.rate.yieldCurveID.saved = false
      state.equity.changed = true
      state.rate.changed = true
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    SET_STRATEGICPARTICIPATIONS(state, payload) {
      state.strategicParticipations.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.strategicParticipations.value = p
      for (i = 0; i < state.strategicParticipations.value.length; i++) {
        var ps = state.strategicParticipations.value[i]
        this.dispatch('solvency/parameters/isStrategicParticipationInPerimeter', ps, { root: true })
      }
      state.strategicParticipations.loaded = true
    },
    SET_STRATEGICPARTICIPATIONS_INPERIMETER(state, payload) {
      state.strategicParticipations.value.find(e => e.code === payload.ps.code && e.portfolio.code === payload.ps.portfolio.code && e.compartment.code === payload.ps.compartment.code).inPerimeter = payload.inPerimeter
      state.strategicParticipations.nbInPerimSet = state.strategicParticipations.nbInPerimSet + 1
    },
    SET_STRATEGICPARTICIPATIONS_CHANGED(state, payload) {
      state.strategicParticipations.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    ADD_STRATEGICPARTICIPATIONS(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.strategicParticipations.value.push(payload[i])
      }
    },
    REMOVE_STRATEGICPARTICIPATIONS(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.strategicParticipations.value.findIndex(e => e.code === payload[i].code && e.portfolio.code === payload[i].portfolio.code && e.compartment.code === payload[i].compartment.code)
        state.strategicParticipations.value.splice(ix, 1)
      }
    },
    SET_STRATEGICPARTICIPATIONS_CIC(state, payload) {
      state.strategicParticipations.availableCIC = payload
      state.strategicParticipations.loadedCIC = true
    },
    SET_STRATEGICPARTICIPATIONS_LOADING(state, payload) {
      state.strategicParticipations.loading = payload
    },
    SET_LTEI(state, payload) {
      state.ltei.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.ltei.value = p
      for (i = 0; i < state.ltei.value.length; i++) {
        var ps = state.ltei.value[i]
        this.dispatch('solvency/parameters/isLteiInPerimeter', ps, { root: true })
      }
      state.ltei.loaded = true
    },
    SET_LTEI_CHANGED(state, payload) {
      state.ltei.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    ADD_LTEI(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.ltei.value.push(payload[i])
      }
    },
    REMOVE_LTEI(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.ltei.value.findIndex(e => e.code === payload[i].code && e.portfolio.code === payload[i].portfolio.code && e.compartment.code === payload[i].compartment.code && e.currency === payload[i].currency)
        state.ltei.value.splice(ix, 1)
      }
    },
    SET_LTEI_INPERIMETER(state, payload) {
      state.ltei.value.find(e => e.code === payload.ps.code && e.portfolio.code === payload.ps.portfolio.code && e.compartment.code === payload.ps.compartment.code && e.currency === payload.ps.currency).inPerimeter = payload.inPerimeter
      state.ltei.nbInPerimSet = state.ltei.nbInPerimSet + 1
    },
    SET_LTEI_CIC(state, payload) {
      state.ltei.availableCIC = payload
      state.ltei.loadedCIC = true
    },
    SET_LTEI_LOADING(state, payload) {
      state.ltei.loading = payload
    },

    SET_INTRAGROUP(state, payload) {
      state.intraGroup.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.intraGroup.value = p
      for (i = 0; i < state.intraGroup.value.length; i++) {
        var ps = state.intraGroup.value[i]
        this.dispatch('solvency/parameters/isIntraGroupInPerimeter', ps, { root: true })
      }
      state.intraGroup.loaded = true
    },
    SET_INTRAGROUP_CHANGED(state, payload) {
      state.intraGroup.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    ADD_INTRAGROUP(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.intraGroup.value.push(payload[i])
      }
    },
    REMOVE_INTRAGROUP(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.intraGroup.value.findIndex(e => e.code === payload[i].code)
        state.intraGroup.value.splice(ix, 1)
      }
    },
    SET_INTRAGROUP_INPERIMETER(state, payload) {
      state.intraGroup.value.find(e => e.code === payload.ps.code).inPerimeter = payload.inPerimeter
      state.intraGroup.nbInPerimSet = state.intraGroup.nbInPerimSet + 1
    },
    SET_INTRAGROUP_LOADING(state, payload) {
      state.intraGroup.loading = payload
    },
    SET_CONCENTRATIONEXCLUSION(state, payload) {
      state.concentrationExclusion.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.concentrationExclusion.value = p
      for (i = 0; i < state.concentrationExclusion.value.length; i++) {
        var ps = state.concentrationExclusion.value[i]
        this.dispatch('solvency/parameters/isConcentrationExclusionInPerimeter', ps, { root: true })
      }
      state.concentrationExclusion.loaded = true
    },
    SET_CONCENTRATIONEXCLUSION_CHANGED(state, payload) {
      state.concentrationExclusion.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    ADD_CONCENTRATIONEXCLUSION(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.concentrationExclusion.value.push(payload[i])
      }
    },
    REMOVE_CONCENTRATIONEXCLUSION(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.concentrationExclusion.value.findIndex(e => e.code === payload[i].code)
        state.concentrationExclusion.value.splice(ix, 1)
      }
    },
    SET_CONCENTRATIONEXCLUSION_INPERIMETER(state, payload) {
      state.concentrationExclusion.value.find(e => e.code === payload.ps.code).inPerimeter = payload.inPerimeter
      state.concentrationExclusion.nbInPerimSet = state.concentrationExclusion.nbInPerimSet + 1
    },
    SET_CONCENTRATIONEXCLUSION_LOADING(state, payload) {
      state.concentrationExclusion.loading = payload
    },

    SET_PROPERTYLEVERAGE(state, payload) {
      state.propertyLeverage.nbInPerimSet = 0
      var p = payload
      for (var i = 0; i < p.length; i++) {
        p[i].inPerimeter = true
      }
      state.propertyLeverage.value = p
      for (i = 0; i < state.propertyLeverage.value.length; i++) {
        var ps = state.propertyLeverage.value[i]
        this.dispatch('solvency/parameters/isPropertyLeverageInPerimeter', ps, { root: true })
      }
      state.propertyLeverage.loaded = true
    },
    SET_PROPERTYLEVERAGE_CHANGED(state, payload) {
      state.propertyLeverage.changed = payload
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
    },
    ADD_PROPERTYLEVERAGE(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        state.propertyLeverage.value.push(payload[i])
      }
    },
    REMOVE_PROPERTYLEVERAGE(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        var ix = state.propertyLeverage.value.findIndex(e => e.code === payload[i].code)
        state.propertyLeverage.value.splice(ix, 1)
      }
    },
    SET_PROPERTYLEVERAGE_RATE(state, payload) {
      var ix = state.propertyLeverage.value.findIndex(e => e.code === payload.code)
      state.propertyLeverage.value[ix].leverageRate = payload.leverageRate
    },
    SET_PROPERTYLEVERAGE_INPERIMETER(state, payload) {
      state.propertyLeverage.value.find(e => e.code === payload.ps.code).inPerimeter = payload.inPerimeter
      state.propertyLeverage.nbInPerimSet = state.propertyLeverage.nbInPerimSet + 1
    },
    SET_PROPERTYLEVERAGE_LOADING(state, payload) {
      state.propertyLeverage.loading = payload
    },
    SET_PROPERTYLEVERAGE_CIC(state, payload) {
      state.propertyLeverage.availableCIC = payload
      state.propertyLeverage.loadedCIC = true
    },
  },
  actions: {
    get_current_parameters: function () {
      this.dispatch('solvency/parameters/load_strategicparticipations', null, { root: true })
      this.dispatch('solvency/parameters/load_ltei', null, { root: true })
      this.dispatch('solvency/parameters/load_intraGroup', null, { root: true })
      this.dispatch('solvency/parameters/load_concentrationExclusion', null, { root: true })
      this.dispatch('solvency/parameters/load_propertyLeverage', null, { root: true })
      this.dispatch('solvency/parameters/load_strategicparticipations_cic', null, { root: true })
      this.dispatch('solvency/parameters/load_ltei_cic', null, { root: true })
      this.dispatch('solvency/parameters/load_propertyLeverage_cic', null, { root: true })
    },
    get_only_parameters: function () {
      this.dispatch('solvency/parameters/load_equity_parameters', null, { root: true })
      this.dispatch('solvency/parameters/load_interestrate_parameters', null, { root: true })
      this.dispatch('solvency/parameters/load_property_parameters', null, { root: true })
      this.dispatch('solvency/parameters/load_currency_parameters', null, { root: true })
      this.dispatch('solvency/parameters/getScrReference', null, { root: true })
    },
    getScrReference: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/scr-reference',
          method: 'GET',
          params: {
            valuationDate: this.state.perimeter.valuationDate.value,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_SCRREFERENCE', response.data, { root: true })
            commit('errors/SET_SCRREFERENCE_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('solvency/parameters/SET_SCRREFERENCE', null, { root: true })
            commit('errors/SET_SCRREFERENCE_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_yieldCurves: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/yield-curves-id',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_RATE_YIELDCURVES', response.data, { root: true })
            commit('errors/SET_YIELDCURVES_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('solvency/parameters/SET_RATE_YIELDCURVES', [], { root: true })
            commit('errors/SET_YIELDCURVES_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_durations: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/modified-duration-priorities',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_DURATIONS', response.data, { root: true })
            commit('errors/SET_DURATIONS_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('solvency/parameters/SET_DURATIONS', [], { root: true })
            commit('errors/SET_DURATIONS_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_equity_parameters: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/scr-equity',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_EQUITY_PARAMETERS', response.data, { root: true })
            commit('errors/SET_EQUITY_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_EQUITY_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_EQUITY_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_interestrate_parameters: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/scr-interest-rate',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_INTERESTRATE_PARAMETERS', response.data, { root: true })
            commit('errors/SET_RATE_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_RATE_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_RATE_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_property_parameters: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/scr-property',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_PROPERTY_PARAMETERS', response.data, { root: true })
            commit('errors/SET_PROPERTY_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_PROPERTY_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_PROPERTY_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_currency_parameters: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/scr-currency',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_CURRENCY_PARAMETERS', response.data, { root: true })
            commit('errors/SET_CURRENCY_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_CURRENCY_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CURRENCY_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_currencies: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/currencies',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_CURRENCIES_ERROR_LOADING', false, { root: true })
            response.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
            commit('solvency/parameters/SET_CURRENCY_CURRENCIES', response.data, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CURRENCIES_ERROR_LOADING', true, { root: true })
            commit('solvency/parameters/SET_CURRENCY_CURRENCIES', [], { root: true })
          })
      })
    },
    save_equity_parameters: function ({ commit }) {
      commit('solvency/parameters/SET_PARAMETERSSAVING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/scr-equity',
          {
            longTermStress: parseFloat(this.state.solvency.parameters.equity.longTermStress.value),
            partStratStress: parseFloat(this.state.solvency.parameters.equity.partStratStress.value),
            type1Stress: parseFloat(this.state.solvency.parameters.equity.type1Stress.value),
            type2Stress: parseFloat(this.state.solvency.parameters.equity.type2Stress.value),
            financialInstitutionInOwnFundTreshold: parseFloat(this.state.solvency.parameters.equity.financialInstitutionInOwnFundTreshold.value),
            type1Type2Correlation: parseFloat(this.state.solvency.parameters.equity.type1Type2Correlation.value),
            useTransitoryStress: this.state.solvency.parameters.equity.useTransitoryStress.value,
            stressRange: parseFloat(this.state.solvency.parameters.equity.stressRange.value),
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('errors/SET_EQUITY_ERROR_SAVING', false, { root: true })
          commit('solvency/parameters/SET_EQUITY_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_EQUITY_ERROR_SAVING', true, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },
    save_interestrate_parameters: function ({ commit }) {
      commit('solvency/parameters/SET_PARAMETERSSAVING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/scr-interest-rate',
          {
            computeMethod: parseFloat(this.state.solvency.parameters.rate.computeMethod.value),
            nominalYieldRealYieldCorrelation: parseFloat(this.state.solvency.parameters.rate.nominalYieldRealYieldCorrelation.value),
            yieldCurveID: this.state.solvency.parameters.rate.yieldCurveID.value,
            minDeltaDown: parseFloat(this.state.solvency.parameters.rate.minDeltaDown.value),
            minDeltaUp: parseFloat(this.state.solvency.parameters.rate.minDeltaUp.value),
            loanDefaultSensi: parseFloat(this.state.solvency.parameters.rate.loanDefaultSensi.value),
            cashDefaultSensi: parseFloat(this.state.solvency.parameters.rate.cashDefaultSensi.value),
            isORSA: this.state.solvency.parameters.rate.isORSA.value,
            isEIOPA2019: this.state.solvency.parameters.rate.isEIOPA2019.value,
            modifiedDurationPriority: this.state.solvency.parameters.rate.modifiedDurationPriority.value,
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('errors/SET_RATE_ERROR_SAVING', false, { root: true })
          commit('solvency/parameters/SET_RATE_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_RATE_ERROR_SAVING', true, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },
    save_property_parameters: function ({ commit }) {
      commit('solvency/parameters/SET_PARAMETERSSAVING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/scr-property',
          {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            propertyStress: parseFloat(this.state.solvency.parameters.property.propertyStress.value),
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('errors/SET_PROPERTY_ERROR_SAVING', false, { root: true })
          commit('solvency/parameters/SET_PROPERTY_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_PROPERTY_ERROR_SAVING', true, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },
    save_currency_parameters: function ({ commit }) {
      commit('solvency/parameters/SET_PARAMETERSSAVING', true, { root: true })
      var ex = {}
      for (var i = 0; i < this.state.solvency.parameters.currency.exceptionStressByCurrencies.value.length; i++) {
        ex[this.state.solvency.parameters.currency.exceptionStressByCurrencies.value[i].code] = this.state.solvency.parameters.currency.exceptionStressByCurrencies.value[i].value
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/scr-currency',
          {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            currencyStress: parseFloat(this.state.solvency.parameters.currency.currencyStress.value),
            referenceCurrency: this.state.solvency.parameters.currency.referenceCurrency.value.code,
            exceptionStressByCurrencies: ex,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('errors/SET_CURRENCY_ERROR_SAVING', false, { root: true })
          commit('solvency/parameters/SET_CURRENCY_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_CURRENCY_ERROR_SAVING', true, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          })
      })
    },

    load_strategicparticipations: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/strategic-parameters',
          method: 'GET',
          params: {
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS', response.data, { root: true })
            commit('errors/SET_STRATEGICPARTICIPATIONS_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_CHANGED', false, { root: true })
          })
          .catch((e) => {
            commit('errors/SET_STRATEGICPARTICIPATIONS_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isStrategicParticipationInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      if (ps.portfolio.code !== '*') {
        url += ' and PortfolioCode eq \'' + ps.portfolio.code + '\''
      }
      if (ps.compartment.code !== '*') {
        url += ' and CompartmentCode eq \'' + ps.compartment.code + '\''
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    saveStrategicParticipationsFromValue: function ({ commit }) {
      commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.solvency.parameters.strategicParticipations.value.length; i++) {
        var el = this.state.solvency.parameters.strategicParticipations.value[i]
        tosend.push({
          roleName: el.roleName,
          code: el.code,
          denomination: el.denomination,
          codeCIC: el.cic.code,
          qrtAdjEqMethVal: el.qrtAdjEqMethVal,
          portfolioCode: el.portfolio.code,
          compartmentCode: el.compartment.code,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/strategic-parameters',
          {
            rolename: this.state.perimeter.client.value.code,
            strategicParticipations: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
          commit('errors/SET_STRATEGICPARTICIPATIONS_ERROR_SAVING', false, { root: true })
          commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
          commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_STRATEGICPARTICIPATIONS_ERROR_SAVING', true, { root: true })
            this.dispatch('solvency/parameters/load_strategicparticipations', null, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_LOADING', false, { root: true })
          })
      })
    },
    load_strategicparticipations_cic: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/strategic-parameters-cic-filters',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_STRATEGICPARTICIPATIONS_CIC', response.data, { root: true })
            commit('errors/SET_CIC_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CIC_ERROR_LOADING', true, { root: true })
          })
      })
    },

    load_ltei: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/long-term-assets',
          method: 'GET',
          params: {
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_LTEI', response.data, { root: true })
            commit('errors/SET_LTEI_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_LTEI_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_LTEI_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isLteiInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      if (ps.portfolio.code !== '*') {
        url += ' and PortfolioCode eq \'' + ps.portfolio.code + '\''
      }
      if (ps.compartment.code !== '*') {
        url += ' and CompartmentCode eq \'' + ps.compartment.code + '\''
      }
      if (ps.currency !== '*') {
        url += ' and QuotationCurrency eq \'' + ps.currency + '\''
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_LTEI_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    saveLteiFromValue: function ({ commit }) {
      commit('solvency/parameters/SET_LTEI_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.solvency.parameters.ltei.value.length; i++) {
        var el = this.state.solvency.parameters.ltei.value[i]
        tosend.push({
          roleName: el.roleName,
          code: el.code,
          denomination: el.denomination,
          portfolioCode: el.portfolio.code,
          compartmentCode: el.compartment.code,
          currency: el.currency,
          codeCIC: el.cic.code,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/long-term-assets',
          {
            rolename: this.state.perimeter.client.value.code,
            longTermAssets: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
          commit('errors/SET_LTEI_ERROR_SAVINGVALUE', false, { root: true })
          commit('solvency/parameters/SET_LTEI_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
          commit('solvency/parameters/SET_LTEI_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_LTEI_ERROR_SAVINGVALUE', true, { root: true })
            this.dispatch('solvency/parameters/load_ltei', null, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('solvency/parameters/SET_LTEI_LOADING', false, { root: true })
          })
      })
    },
    load_ltei_cic: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/long-term-assets-cic-filters',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_LTEI_CIC', response.data, { root: true })
            commit('errors/SET_CICLTEI_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CICLTEI_ERROR_LOADING', true, { root: true })
          })
      })
    },

    load_intraGroup: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/intra-groups',
          method: 'GET',
          params: {
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_INTRAGROUP', response.data, { root: true })
            commit('errors/SET_INTRAGROUP_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_INTRAGROUP_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_INTRAGROUP_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isIntraGroupInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_INTRAGROUP_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    saveIntraGroupFromValue: function ({ commit }) {
      commit('solvency/parameters/SET_INTRAGROUP_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.solvency.parameters.intraGroup.value.length; i++) {
        var el = this.state.solvency.parameters.intraGroup.value[i]
        tosend.push({
          roleName: el.roleName,
          code: el.code,
          denomination: el.denomination,
          codeCIC: el.cic.code,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/intra-groups',
          {
            rolename: this.state.perimeter.client.value.code,
            intraGroups: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
          commit('errors/SET_INTRAGROUP_ERROR_SAVINGVALUE', false, { root: true })
          commit('solvency/parameters/SET_INTRAGROUP_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
          commit('solvency/parameters/SET_INTRAGROUP_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_INTRAGROUP_ERROR_SAVINGVALUE', true, { root: true })
            this.dispatch('solvency/parameters/load_intragroup', null, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('solvency/parameters/SET_INTRAGROUP_LOADING', false, { root: true })
          })
      })
    },

    load_concentrationExclusion: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/exclude-scr-concentration',
          method: 'GET',
          params: {
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION', response.data, { root: true })
            commit('errors/SET_CONCENTRATIONEXCLUSION_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CONCENTRATIONEXCLUSION_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isConcentrationExclusionInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    saveConcentrationExclusionFromValue: function ({ commit }) {
      commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.solvency.parameters.concentrationExclusion.value.length; i++) {
        var el = this.state.solvency.parameters.concentrationExclusion.value[i]
        tosend.push({
          roleName: el.roleName,
          code: el.code,
          denomination: el.denomination,
          codeCIC: el.cic.code,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/exclude-scr-concentration',
          {
            rolename: this.state.perimeter.client.value.code,
            excludeSCRConcentrations: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
          commit('errors/SET_CONCENTRATIONEXCLUSION_ERROR_SAVINGVALUE', false, { root: true })
          commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
          commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_CONCENTRATIONEXCLUSION_ERROR_SAVINGVALUE', true, { root: true })
            this.dispatch('solvency/parameters/load_concentrationExclusion', null, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('solvency/parameters/SET_CONCENTRATIONEXCLUSION_LOADING', false, { root: true })
          })
      })
    },

    load_propertyLeverage: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/property-leverage',
          method: 'GET',
          params: {
            roleName: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_PROPERTYLEVERAGE', response.data, { root: true })
            commit('errors/SET_PROPERTYLEVERAGE_ERROR_LOADING', false, { root: true })
            commit('solvency/parameters/SET_PROPERTYLEVERAGE_CHANGED', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_PROPERTYLEVERAGE_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isPropertyLeverageInPerimeter: function ({ commit }, ps) {
      var id = this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId
      var url = this.state.baseUrl + '/api/inventory/' + id + '/odata/hierarchical_positions'
      url += '?$filter=IsinCode eq \'' + ps.code + '\''
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: url,
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_PROPERTYLEVERAGE_INPERIMETER', { ps: ps, inPerimeter: response.data.length > 0 }, { root: true })
          })
          .catch((result) => {
          })
      })
    },
    savePropertyLeverageFromValue: function ({ commit }) {
      commit('solvency/parameters/SET_PROPERTYLEVERAGE_LOADING', true, { root: true })
      var tosend = []
      for (var i = 0; i < this.state.solvency.parameters.propertyLeverage.value.length; i++) {
        var el = this.state.solvency.parameters.propertyLeverage.value[i]
        tosend.push({
          roleName: el.roleName,
          code: el.code,
          denomination: el.denomination,
          leverageRate: el.leverageRate,
          ownUse: el.ownUse,
          codeCIC: el.cic.code,
        })
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios.put(this.state.baseUrl + '/api/parameters/property-leverage',
          {
            rolename: this.state.perimeter.client.value.code,
            propertyLeverages: tosend,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
          commit('solvency/parameters/SET_PARAMETERS_CHANGED', true, { root: true })
          commit('errors/SET_PROPERTYLEVERAGE_ERROR_SAVINGVALUE', false, { root: true })
          commit('solvency/parameters/SET_PROPERTYLEVERAGE_CHANGED', false, { root: true })
          commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
          commit('solvency/parameters/SET_PROPERTYLEVERAGE_LOADING', false, { root: true })
        })
          .catch(() => {
            commit('errors/SET_PROPERTYLEVERAGE_ERROR_SAVINGVALUE', true, { root: true })
            this.dispatch('solvency/parameters/load_propertyLeverage', null, { root: true })
            commit('solvency/parameters/SET_PARAMETERSSAVING', false, { root: true })
            commit('solvency/parameters/SET_PROPERTYLEVERAGE_LOADING', false, { root: true })
          })
      })
    },
    load_propertyLeverage_cic: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/parameters/property-leverage-filters',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('solvency/parameters/SET_PROPERTYLEVERAGE_CIC', response.data, { root: true })
            commit('errors/SET_CICPROPERTYLEVERAGE_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_CICPROPERTYLEVERAGE_ERROR_LOADING', true, { root: true })
          })
      })
    },
  },
}
