export default {
  namespaced: true,
  state: {
    detailsPbi: undefined,
    historicalPbi: undefined,
    comparisonPbi: undefined,
  },
  getters: {
    detailsPbi: state => {
      return state.detailsPbi
    },
    historicalPbi: state => {
      return state.historicalPbi
    },
    comparisonPbi: state => {
      return state.comparisonPbi
    },
  },
  mutations: {
    RESET (state) {
      state.detailsPbi = undefined
      state.historicalPbi = undefined
      state.comparisonPbi = undefined
    },
    SET_PBIS: function (state) {
      state.detailsPbi = {
        groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
        reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESDETAILS_REPORTID,
      }
      state.historicalPbi = {
        groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
        reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESHISTO_REPORTID,
      }
      state.comparisonPbi = {
        groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
        reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESCOMPA_REPORTID,
      }
    },
  },
  actions: {
  },
}
