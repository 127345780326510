import Axios from 'axios'
import Vue from 'vue'
import router from '../../router'
import FileSaver from 'file-saver'
import archives from './archives'

export default {
  namespaced: true,
  modules: {
    archives: archives,
  },
  state: {
    loading: false,
    flatPositions: [],
    loaded: false,
    pbiLoaded: false,
    transparisationDate: undefined,
    pbi: undefined,
    perimeterId: undefined,
    totalMarketValuation: null,
    transpaReportLoading: false,
  },
  getters: {
    inventoryLoaded: state => {
      return state.loaded
    },
    inventoryLoading: state => {
      return state.loading
    },
    transparisationDate: state => {
      return state.transparisationDate
    },
    pbi: state => {
      return state.pbi
    },
    perimeterId: state => {
      return state.perimeterId
    },
    totalMarketValuation: state => {
      return state.totalMarketValuation
    },
    transpaReportLoading: state => {
      return state.transpaReportLoading
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false
      state.flatPositions = []
      state.loaded = false
      state.pbiLoaded = false
      state.transparisationDate = undefined
      state.pbi = undefined
      state.perimeterId = undefined
      state.totalMarketValuation = null
      state.transpaReportLoading = false
      this.commit('transparisation/archives/RESET', null, { root: true })
    },
    SET_PBI(state, payload) {
      state.pbi = {
        groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
        reportId: process.env.VUE_APP_POWERBI_TRANSPA_REPORTID,
      }
    },
    SET_HIERARCHICALPOSITIONS_LOADING(state, payload) {
      state.loading = payload
    },
    SET_LOADED(state, payload) {
      state.loaded = payload
      state.transparisationDate = new Date()
    },
    EMPTY_HIERARCHICALPOSITIONS(state) {
      state.loading = false
      state.flatPositions = []
      state.loaded = false
      state.perimeterID = undefined
    },
    SET_PERIMETERID(state, payload) {
      state.perimeterId = payload
      state.loaded = true
      if (payload) {
        this.dispatch('refreshOpenedTab', undefined, { root: true })
      }
    },
    SET_TOTALMARKETVALUATION(state, payload) {
      state.totalMarketValuation = payload
    },
    SET_TRANSPAREPORTLOADING(state, payload) {
      state.transpaReportLoading = payload
    },
  },
  actions: {
    savePerimeter: function () {
      var url = this.state.baseUrl + '/api/arago/perimeter'
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.put(url,
          {
            valuationDate: this.state.perimeter.valuationDate.value,
            rolename: this.state.perimeter.client.value.code,
            portfolios: this.state.perimeter.portfolios.value.map(e => e.code),
            compartments: this.state.perimeter.compartments.value.map(e => e.code),
            lookThroughLevel: this.state.perimeter.transparencyLevel.value.code,
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        )
      })
    },
    getTotalMarketValuation: function ({ commit }) {
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/inventory/global-market-valuation/',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
          params: {
            perimeterID: this.state.simulation.simulationMode && this.state.simulation.transparised ? this.state.simulation.perimeterId : this.state.transparisation.perimeterId,
          },
        })
          .then((response) => {
            commit('transparisation/SET_TOTALMARKETVALUATION', response.data, { root: true })
          })
          .catch(() => {
          })
      })
    },
    computeSaveHierarchicalPositions: function ({ commit, dispatch }) {
      commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', true, { root: true })
      commit('transparisation/SET_LOADED', false, { root: true })
      dispatch('transparisation/savePerimeter', null, { root: true }).then(() => {
        commit('perimeter/SET_ISVALID', true, { root: true })
        var url = this.state.baseUrl + '/api/inventory/computation'
        return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
          return Axios.post(url,
            {
              valuationDate: this.state.perimeter.valuationDate.value,
              rolename: this.state.perimeter.client.value.code,
              portfolios: this.state.perimeter.portfolios.value.map(e => e.code),
              compartments: this.state.perimeter.compartments.value.map(e => e.code),
              lookThroughLevel: this.state.perimeter.transparencyLevel.value.code,
              userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            },
            {
              headers: {
                authorization: config.headers.authorization,
              },
            },
          ).then((response) => {
            dispatch('solvency/parameters/get_only_parameters', null, { root: true })
            dispatch('solvency/parameters/get_current_parameters', null, { root: true })
            dispatch('esg/parameters/getParameters', null, { root: true })
            dispatch('risk/parameters/getParameters', null, { root: true })
            commit('transparisation/SET_PERIMETERID', response.data.perimeter.perimeterID, { root: true })
            commit('errors/SET_HIERARCHICALPOSITIONS_ERROR_SAVING', false, { root: true })
            commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', false, { root: true })
            commit('transparisation/SET_LOADED', true, { root: true })
            dispatch('transparisation/getTotalMarketValuation', null, { root: true })
          })
            .catch(() => {
              commit('transparisation/SET_PERIMETERID', undefined, { root: true })
              commit('errors/SET_HIERARCHICALPOSITIONS_ERROR_SAVING', true, { root: true })
              commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', false, { root: true })
              commit('transparisation/SET_LOADED', false, { root: true })
              commit('transparisation/EMPTY_HIERARCHICALPOSITIONS', null, { root: true })
            })
        })
      }).catch(() => {
        commit('transparisation/SET_PERIMETERID', undefined, { root: true })
        commit('errors/SET_HIERARCHICALPOSITIONS_ERROR_SAVING', true, { root: true })
        commit('transparisation/SET_LOADED', false, { root: true })
        commit('transparisation/SET_HIERARCHICALPOSITIONS_LOADING', false, { root: true })
        commit('transparisation/EMPTY_HIERARCHICALPOSITIONS', null, { root: true })
      })
    },
    refresh: function () {
      router.go()
    },

    compute: function ({ commit }, toCompute) {
      commit('solvency/results/SET_SCRDONE', false, { root: true })
      commit('solvency/results/SET_ARCHIVESCREXISTS', false, { root: true })
      commit('esg/results/SET_ESG_DONE', false, { root: true })
      commit('esg/results/SET_ARCHIVEESGEXISTS', false, { root: true })
      commit('risk/results/SET_RISK_DONE', false, { root: true })
      commit('risk/results/SET_ARCHIVERISKEXISTS', false, { root: true })
      if (toCompute.computeSCR) {
        commit('solvency/results/SET_SCRLOADING', true, { root: true })
      }
      if (toCompute.computeESG) {
        commit('esg/results/SET_ESG_LOADING', true, { root: true })
      }
      if (toCompute.computeRisk) {
        commit('risk/results/SET_RISK_LOADING', true, { root: true })
      }
      var url = this.state.simulation.simulationMode ? this.state.baseUrl + '/api/simulation/computation' : this.state.baseUrl + '/api/inventory/computation'
      var params = this.state.simulation.simulationMode ? {
        userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
        computeESG: toCompute.computeESG,
        computeSCR: toCompute.computeSCR,
        computeRiskLiquidity: toCompute.computeRisk,
        simulationID: this.state.simulation.simulationID,
      } : {
        valuationDate: this.state.perimeter.valuationDate.value,
        rolename: this.state.perimeter.client.value.code,
        portfolios: this.state.perimeter.portfolios.value.map(e => e.code),
        compartments: this.state.perimeter.compartments.value.map(e => e.code),
        lookThroughLevel: this.state.perimeter.transparencyLevel.value.code,
        userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
        computeESG: toCompute.computeESG,
        computeSCR: toCompute.computeSCR,
        computeRiskLiquidity: toCompute.computeRisk,
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(url,
          params,
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          if (this.state.simulation.simulationMode) {
            commit('simulation/SET_PERIMETERID', response.data.perimeter.perimeterID, { root: true })
          } else {
            commit('transparisation/SET_PERIMETERID', response.data.perimeter.perimeterID, { root: true })
          }
          if (toCompute.computeESG) {
            commit('esg/results/SET_RESULTS', response.data, { root: true })
            commit('esg/results/SET_ESG_LOADING', false, { root: true })
            commit('esg/results/SET_ESG_DONE', true, { root: true })
            commit('esg/parameters/SET_PARAMETERSCHANGED', false, { root: true })
          }
          if (toCompute.computeSCR) {
            commit('solvency/results/SET_SCRMARKETRESULT', response.data, { root: true })
            commit('solvency/results/SET_SCRLOADING', false, { root: true })
            commit('solvency/results/SET_SCRDONE', true, { root: true })
          }
          if (toCompute.computeRisk) {
            commit('risk/results/SET_RESULTS', response.data, { root: true })
            commit('risk/results/SET_RISK_LOADING', false, { root: true })
            commit('risk/results/SET_RISK_DONE', true, { root: true })
            commit('risk/parameters/SET_PARAMETERSCHANGED', false, { root: true })
          }
          commit('errors/SET_COMPUTING_ERROR', false, { root: true })
        })
          .catch((e) => {
            commit('errors/SET_COMPUTING_ERROR', true, { root: true })
            if (this.state.simulation.simulationMode) {
              commit('simulation/SET_PERIMETERID', undefined, { root: true })
            } else {
              commit('transparisation/SET_PERIMETERID', undefined, { root: true })
            }
            if (toCompute.computeESG) {
              commit('esg/results/SET_RESULTS', undefined, { root: true })
              commit('esg/results/SET_ESG_LOADING', false, { root: true })
              commit('esg/results/SET_ESG_DONE', false, { root: true })
            }
            if (toCompute.computeRisk) {
              commit('risk/results/SET_RESULTS', undefined, { root: true })
              commit('risk/results/SET_RISK_LOADING', false, { root: true })
              commit('risk/results/SET_RISK_DONE', false, { root: true })
            }
            if (toCompute.computeSCR) {
              commit('solvency/results/SET_SCRMARKETRESULT', undefined, { root: true })
              commit('solvency/results/SET_SCRLOADING', false, { root: true })
              commit('solvency/results/SET_SCRDONE', false, { root: true })
            }
          })
      })
    },
    exportTranspaExcel: function ({ commit }) {
      commit('solvency/results/SET_TRANSPAREPORTLOADING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/reporting/lookthrough',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          responseType: 'blob',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_REPORTS_TRANSPA_ERROR', false, { root: true })
            commit('solvency/results/SET_TRANSPAREPORTLOADING', false, { root: true })
            const filename = response.headers['content-disposition']
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim()
            FileSaver.saveAs(new Blob([response.data]), filename)
          })
          .catch(() => {
            commit('errors/SET_REPORTS_TRANSPA_ERROR', true, { root: true })
            commit('solvency/results/SET_TRANSPAREPORTLOADING', false, { root: true })
          })
      })
    },
  },
}
