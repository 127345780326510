import Axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    perimeterChanged: false,
    perimeterLoaded: false,
    loadingFromDatabase: true,
    isValid: true,
    rolenames: [],
    client: {
      label: 'Rôle',
      value: undefined,
    },
    portfolios: {
      label: 'Portefeuilles',
      value: [],
    },
    portfoliosList: [],
    portfoliosHierarchy: [],
    portfoliosWithCompartmentsBuilded: false,
    valuationDate: {
      label: 'Date de valorisation',
      value: undefined,
      formatedValue: undefined,
    },
    datesList: [],
    compartments: {
      label: 'Compartiments',
      value: [],
    },
    compartmentsList: [],
    transparencyLevel: {
      label: 'Niveau de transparence',
      value: undefined,
    },
  },
  getters: {
    client: state => {
      return state.client
    },
    portfoliosList: state => {
      return state.portfoliosList
    },
    portfoliosHierarchy: state => {
      return state.portfoliosHierarchy
    },
    rolenameEmpty: state => {
      return state.client.value === undefined
    },
    compartmentsBuilding: (state, getters) => {
      return !state.portfoliosWithCompartmentsBuilded
    },
    portfoliosNameList: state => {
      var list = ''
      for (var i = 0; i < state.portfolios.value.length; i++) {
        if (i > 0) {
          list += ', '
        }
        list += state.portfolios.value[i].name
      }
      return list
    },
    compartmentsNameList: state => {
      var list = ''
      for (var i = 0; i < state.compartments.value.length; i++) {
        if (i > 0) {
          list += ', '
        }
        list += state.compartments.value[i].name
      }
      return list
    },
    perimeterLoaded: state => {
      return state.perimeterLoaded
    },
    perimeter: state => {
      return state
    },
    perimeterChanged: state => {
      return state.perimeterChanged
    },
    isValid: state => {
      return state.isValid
    },
    isPerimeterEmpty: state => {
      return state.portfolios.value.length === 0 && state.valuationDate.value === undefined && state.compartments.value.length === 0 && state.transparencyLevel.value === undefined
    },
  },
  mutations: {
    RESET(state) {
      state.perimeterChanged = false
      state.perimeterLoaded = false
      state.loadingFromDatabase = true
      state.isValid = true
      state.rolenames = []
      state.client = {
        label: 'Rôle',
        value: undefined,
      }
      state.portfolios = {
        label: 'Portefeuilles',
        value: [],
      }
      state.portfoliosList = []
      state.portfoliosHierarchy = []
      state.valuationDate = {
        label: 'Date de valorisation',
        value: undefined,
        formatedValue: undefined,
      }
      state.datesList = []
      state.compartments = {
        label: 'Compartiments',
        value: [],
      }
      state.compartmentsList = []
      state.transparencyLevel = {
        label: 'Niveau de transparence',
        value: undefined,
      }
      state.portfoliosWithCompartmentsBuilded = false
    },
    SET_ROLENAMES(state, payload) {
      state.rolenames = payload
    },
    SET_PERIMETER_CHANGED(state, payload) {
      state.perimeterChanged = payload
      if (payload) {
        this.commit('transparisation/EMPTY_HIERARCHICALPOSITIONS', null, { root: true })
        this.commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
        this.commit('simulation/RESET_SIMULATION', null, { root: true })
        this.commit('solvency/results/SET_SCRDONE', false, { root: true })
        this.commit('esg/results/SET_ESG_DONE', false, { root: true })
        this.commit('risk/results/SET_RISK_DONE', false, { root: true })
      }
    },
    SET_CLIENT_CHANGE(state) {
      this.commit('transparisation/EMPTY_HIERARCHICALPOSITIONS', null, { root: true })
      this.commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
      this.commit('solvency/results/SET_SCRDONE', false, { root: true })
      this.commit('esg/results/SET_ESG_DONE', false, { root: true })
      this.commit('risk/results/SET_RISK_DONE', false, { root: true })
      this.commit('solvency/results/SET_SCRREADY', false, { root: true })
      this.commit('solvency/parameters/SET_PARAMETERS_CHANGED', false, { root: true })
      this.commit('esg/parameters/SET_PARAMETERSCHANGED', false, { root: true })
      this.commit('perimeter/SET_PERIMETER_CHANGED', true, { root: true })
      state.perimeterLoaded = true
      state.loadingFromDatabase = false
      state.portfoliosWithCompartmentsBuilded = false
    },
    SET_CLIENT(state, payload) {
      state.client.value = payload
      if (payload !== undefined && payload.code !== undefined) {
        this.dispatch('archives/getArchives', null, { root: true })
      }
      if (payload !== undefined && payload.code !== undefined && (payload.name === '' || payload.name === undefined)) {
        this.dispatch('perimeter/get_rolename_name', payload.code, { root: true }).then(response => {
          state.client.value.name = response
        })
      }
    },
    SET_PORTFOLIOS(state, payload) {
      state.portfolios.value = payload
    },
    SET_PORTFOLIOSLIST(state, payload) {
      state.portfoliosList = payload.map(function (o) {
        return { code: o.codePortefeuille, name: o.libellePortefeuille, rolename: o.rolename, indice: o.indice }
      })
      if (!state.portfoliosWithCompartmentsBuilded) {
        this.commit('perimeter/SET_PORTFOLIOHIERARCHY', undefined, { root: true })
      }
    },
    SET_PORTFOLIOHIERARCHY(state) {
      state.portfoliosWithCompartmentsBuilded = false
      state.portfoliosHierarchy = state.portfoliosList
      this.dispatch('perimeter/getAllCompartments', undefined, { root: true })
    },
    SET_COMPARTMENTSHIERARCHY(state, payload) {
      for (var j = 0; j < state.portfoliosHierarchy.length; j++) {
        state.portfoliosHierarchy[j].compartments = payload.filter(e => e.codePortefeuille === state.portfoliosHierarchy[j].code).map(function (o) {
          return { code: o.codeCompartiment, name: o.libelleCompartiment }
        })
      }
      state.portfoliosWithCompartmentsBuilded = true
    },
    SET_DATE(state, payload) {
      state.valuationDate.value = payload
      if (payload === undefined || payload === '') {
        state.valuationDate.formatedValue = undefined
      } else {
        state.valuationDate.formatedValue = payload.substr(8, 2) + '/' + payload.substr(5, 2) + '/' + payload.substr(0, 4)
      }
    },
    SET_DATESLIST(state, payload) {
      state.datesList = payload.sort().reverse()
    },
    SET_COMPARTMENTS(state, payload) {
      state.compartments.value = payload
    },
    SET_COMPARTMENTSLIST(state, payload) {
      state.compartmentsList = payload.map(function (e) {
        return { code: e.codeCompartiment, name: e.libelleCompartiment }
      })
    },
    SET_TRANSPARENCYLEVEL(state, payload) {
      state.transparencyLevel.value = payload
    },
    SET_PERIMETER(state, payload) {
      if (payload === '') {
        this.commit('perimeter/EMPTY_PERIMETER', null, { root: true })
        return
      }
      this.commit('perimeter/SET_DATE', payload.valuationDate, { root: true })
      this.commit('perimeter/SET_CLIENT', { code: payload.rolename, name: '' }, { root: true })
      this.commit('perimeter/SET_TRANSPARENCYLEVEL', { code: payload.lookThroughLevel, name: payload.lookThroughLevel }, { root: true })
      this.commit('perimeter/SET_PORTFOLIOS', payload.portfolios, { root: true })
      this.commit('perimeter/SET_COMPARTMENTS', payload.compartments, { root: true })
      state.loadingFromDatabase = false
      state.perimeterLoaded = true
    },
    EMPTY_PERIMETER(state) {
      if (this.state.isAdmin) {
        state.client = {
          label: 'Rôle',
          value: undefined,
        }
        this.dispatch('perimeter/load_rolenames')
      } else if (state.client.value !== undefined) {
        this.dispatch('perimeter/load_portfolios')
      }
      state.portfolios = {
        label: 'Portefeuilles',
        value: [],
      }
      state.portfoliosList = []
      state.portfoliosHierarchy = []
      state.valuationDate = {
        label: 'Date de valorisation',
        value: undefined,
        formatedValue: undefined,
      }
      state.datesList = []
      state.compartments = {
        label: 'Compartiments',
        value: [],
      }
      state.compartmentsList = []
      state.transparencyLevel = {
        label: 'Niveau de transparence',
        value: undefined,
      }
      state.loadingFromDatabase = false
      state.perimeterLoaded = true
    },
    SET_ISVALID(state, payload) {
      state.isValid = payload
    },
  },
  actions: {
    get_rolename: function () {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/inventory/rolenames',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            this.commit('perimeter/SET_CLIENT', response.data.find(e => e.code !== 'Solvency'), { root: true })
            this.dispatch('perimeter/load_portfolios', undefined, { root: true })
            this.commit('errors/SET_ROLENAME_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            this.commit('perimeter/SET_CLIENT', [], { root: true })
            this.commit('errors/SET_ROLENAME_ERROR_LOADING', true, { root: true })
          })
      })
    },
    load_rolenames: function ({ commit }) {
      if (this.state.isAdmin) {
        return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
          return Axios({
            url: this.state.baseUrl + '/api/inventory/all-rolenames',
            method: 'GET',
            headers: {
              authorization: config.headers.authorization,
            },
          })
            .then((response) => {
              commit('errors/SET_ROLENAMES_ERROR_LOADING', false, { root: true })
              commit('perimeter/SET_ROLENAMES', response.data.filter(role => role.code.includes('Client.') || role.code.includes('FDLCCP') || role.code.includes('fdlc')), { root: true })
            })
            .catch(() => {
              commit('errors/SET_ROLENAMES_ERROR_LOADING', true, { root: true })
              commit('perimeter/SET_ROLENAMES', [], { root: true })
            })
        })
      } else {
        return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
          return Axios({
            url: this.state.baseUrl + '/api/inventory/rolenames',
            method: 'GET',
            params: {
              userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            },
            headers: {
              authorization: config.headers.authorization,
            },
          })
            .then((response) => {
              commit('perimeter/SET_ROLENAMES', response.data, { root: true })
            })
            .catch(() => {
              commit('perimeter/SET_ROLENAMES', [], { root: true })
            })
        })
      }
    },
    get_rolename_name: function ({ dispatch }, code) {
      return dispatch('perimeter/load_rolenames', null, { root: true }).then(() => {
        if (this.state.perimeter.rolenames.find(e => e.code === code) === undefined) {
          return ''
        }
        return this.state.perimeter.rolenames.find(e => e.code === code).name
      })
    },
    load_portfolios: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/inventory/portfolios',
          method: 'GET',
          params: {
            rolename: this.state.perimeter.client.value.code,
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('perimeter/SET_PORTFOLIOSLIST', response.data, { root: true })
            commit('errors/SET_PORTFOLIOS_ERROR_LOADING', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_PORTFOLIOS_ERROR_LOADING', true, { root: true })
            commit('perimeter/SET_PORTFOLIOSLIST', [], { root: true })
          })
      })
    },
    load_dates: function ({ commit }) {
      var portfoliosCode = []
      for (var i = 0; i < this.state.perimeter.portfolios.value.length; i++) {
        portfoliosCode.push(this.state.perimeter.portfolios.value[i].code)
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(this.state.baseUrl + '/api/inventory/valuation-dates',
          {
            // rolename: this.state.perimeter.client.value.code,
            portfolioCodes: portfoliosCode,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('errors/SET_DATES_ERROR_LOADING', false, { root: true })
          commit('perimeter/SET_DATESLIST', response.data, { root: true })
        })
          .catch(() => {
            commit('errors/SET_DATES_ERROR_LOADING', true, { root: true })
            commit('perimeter/SET_DATESLIST', [], { root: true })
          })
      })
    },
    load_compartments: function ({ commit }) {
      var portfoliosCode = []
      for (var i = 0; i < this.state.perimeter.portfolios.value.length; i++) {
        portfoliosCode.push(this.state.perimeter.portfolios.value[i].code)
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(this.state.baseUrl + '/api/inventory/compartments',
          {
            // rolename: this.state.perimeter.client.value.code,
            portfolioCodes: portfoliosCode,
            valuationDate: this.state.perimeter.valuationDate.value,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('errors/SET_COMPARTMENTS_ERROR_LOADING', false, { root: true })
          commit('perimeter/SET_COMPARTMENTSLIST', response.data, { root: true })
        })
          .catch(() => {
            commit('errors/SET_COMPARTMENTS_ERROR_LOADING', true, { root: true })
            commit('perimeter/SET_COMPARTMENTSLIST', [], { root: true })
          })
      })
    },
    getAllCompartments: function ({ commit }) {
      var portfoliosCode = []
      for (var i = 0; i < this.state.perimeter.portfoliosList.length; i++) {
        portfoliosCode.push(this.state.perimeter.portfoliosList[i].code)
      }
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(this.state.baseUrl + '/api/inventory/compartments',
          {
            // rolename: this.state.perimeter.client.value.code,
            portfolioCodes: portfoliosCode,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
        ).then((response) => {
          commit('perimeter/SET_COMPARTMENTSHIERARCHY', response.data, { root: true })
        })
          .catch(() => {
            commit('perimeter/SET_COMPARTMENTSLIST', [], { root: true })
          })
      })
    },
    load_perimeter: function ({ commit, dispatch }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/arago/perimeter',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('perimeter/SET_PERIMETER', response.data, { root: true })
            dispatch('perimeter/isPerimeterValid', response.data, { root: true }).then(valid => {
              commit('perimeter/SET_ISVALID', valid, { root: true })
              if (!valid) {
                commit('perimeter/EMPTY_PERIMETER', undefined, { root: true })
              }
              commit('errors/SET_PERIMETER_ERROR_LOADING', false, { root: true })
            })
          })
          .catch(() => {
            commit('perimeter/EMPTY_PERIMETER', undefined, { root: true })
            // commit('errors/SET_PERIMETER_ERROR_LOADING', true, { root: true })
          })
      })
    },
    isPerimeterValid: function ({ dispatch }, perimeter) {
      if (!this.state.isAdmin) {
        if (this.state.perimeter.client.value.code !== perimeter.rolename) {
          return false
        }
      }
      return dispatch('perimeter/load_portfolios', null, { root: true }).then(() => {
        for (var i = 0; i < perimeter.portfolios.length; i++) {
          if (this.state.perimeter.portfoliosList.find(e => e.code === perimeter.portfolios[i].code) === undefined) {
            return false
          }
        }
        return dispatch('perimeter/load_dates', null, { root: true }).then(() => {
          if (perimeter.valuationDate !== undefined && this.state.perimeter.datesList.find(e => e.substr(0, 10) === perimeter.valuationDate.substr(0, 10)) === undefined) {
            return false
          }
          return dispatch('perimeter/load_compartments', null, { root: true }).then(() => {
            for (var i = 0; i < perimeter.compartments.length; i++) {
              if (this.state.perimeter.compartmentsList.find(e => e.code === perimeter.compartments[i].code) === undefined) {
                return false
              }
            }
            return true
          })
        })
      })
    },
    // save_perimeter: function ({ commit }) {
    //   var url = this.state.baseUrl + '/api/arago/perimeter'
    //   return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
    //     return Axios.put(url,
    //       {
    //         valuationDate: this.state.perimeter.valuationDate.value,
    //         rolename: this.state.perimeter.client.value.code,
    //         portfolios: this.state.perimeter.portfolios.value,
    //         compartments: this.state.perimeter.compartments.value,
    //         lookThroughLevel: this.state.perimeter.transparencyLevel.value.code,
    //         userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
    //       },
    //       {
    //         headers: {
    //           authorization: config.headers.authorization,
    //         },
    //       },
    //       ).then((response) => {
    //         commit('errors/SET_PERIMETER_ERROR_SAVING', false, { root: true })
    //       })
    //       .catch(() => {
    //         commit('errors/SET_PERIMETER_ERROR_SAVING', true, { root: true })
    //       })
    //   })
    // },
  },
}
