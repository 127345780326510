import Axios from 'axios'
import Vue from 'vue'

function getOnlyText (htmlText) {
  var temporaryDivElement = document.createElement('div')
  temporaryDivElement.innerHTML = htmlText
  while (temporaryDivElement.firstChild.tagName === 'DIV') {
    temporaryDivElement = temporaryDivElement.firstChild
  }
  return temporaryDivElement.innerHTML
}

export default {
  namespaced: true,
  state: {
    news: [],
    loading: false,
    sharepointUrl: 'https://financieredelacite2.sharepoint.com/sites/Arago',
  },
  getters: {
    news: state => {
      return state.news
    },
    loading: state => {
      return state.loading
    },
  },
  mutations: {
    RESET (state) {
      state.news = []
      state.loading = false
      state.sharepointUrl = 'https://financieredelacite2.sharepoint.com/sites/Arago'
    },
    SET_NEWS_LOADING: function (state, payload) {
      state.loading = payload
    },
    SET_NEWS: function (state, payload) {
      payload.sort(function (a, b) {
        return b.Id - a.Id
      })
      state.news = []
      for (var i = 0; i < payload.length; i++) {
        var d = payload[i].Date_x0020_de_x0020_publication
        state.news.push({
          title: payload[i].Title,
          img: payload[i].Logo !== null ? payload[i].Logo.Url : undefined,
          date: d.substr(8, 2) + '/' + d.substr(5, 2) + '/' + d.substr(0, 4),
          pdfUrl: payload[i].Lien !== null ? payload[i].Lien.Url : undefined,
          websiteUrl: payload[i].Source !== null ? payload[i].Source.Url : undefined,
          intro: getOnlyText(payload[i].Texte),
        })
      }
    },
  },
  actions: {
    getSharepointNews: function ({ commit }) {
      commit('SET_NEWS_LOADING', true)
      Vue.prototype.$AuthService
        .getAxiosTokenConfig()
        .then(config => {
          Axios({
            url: this.state.baseUrl + '/api/microsoft-tools/token',
            method: 'GET',
            headers: {
              authorization: config.headers.authorization,
            },
            params: {
              tokenType: 'Sharepoint',
            },
          }).then(response => {
            return Axios({
              url: this.state.news.sharepointUrl + '/_api/lists/getbytitle(%27Actualités%27)/items',
              method: 'GET',
              headers: {
                authorization: 'Bearer ' + response.data,
                Accept: 'application/json;odata=verbose',
              },
            })
              .then((res) => {
                commit('news/SET_NEWS', res.data.d.results, { root: true })
                commit('SET_NEWS_LOADING', false)
                commit('errors/SET_LOADINGNEWS_ERROR', false, { root: true })
              })
              .catch(() => {
                commit('SET_NEWS_LOADING', false)
                commit('news/SET_NEWS', [], { root: true })
                commit('errors/SET_LOADINGNEWS_ERROR', true, { root: true })
              })
          })
          .catch(() => {
            commit('news/SET_NEWS', [], { root: true })
            commit('errors/SET_LOADINGNEWS_ERROR', true, { root: true })
          })
        })
    },
  },
}
