<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="`primary--text selected-menu`"
    :disabled="disabled"
    link
    :style="`opacity: ${ opacity };`"
  >
    <v-list-item-icon v-if="item.icon">
      <v-icon
        size="1.4rem"
        v-text="item.icon"
      />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title
        :class="{'disabled-text': disabled}"
        class="text-body-1"
      >
        {{ item.title }}
        <v-tooltip
          v-if="warning"
          bottom
          color="tooltip"
          max-width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                color="orange"
                small
                right
              >
                mdi-alert-circle-outline
              </v-icon>
            </span>
          </template>
          <span>Des modifications on été effectuées dans le périmètre ou certains paramètres, les résultats ci-dessous n'en tiennent pas compte et reflètent le dernier calcul effectué.</span>
        </v-tooltip>
      </v-list-item-title>

      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      warning: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
      opacity () {
        if (this.disabled) {
          return 0.5
        }
        return 1
      },
    },
  }
</script>
<style scoped>
  .disabled-text {
    color: grey !important;
  }
  .selected-menu {
    border-right: 4px var(--v-primary-base) solid;
  }
  .selected-menu .v-list-item__title {
    font-weight: bold !important;
  }
</style>
