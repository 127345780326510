import Axios from 'axios'
import Vue from 'vue'
import FileSaver from 'file-saver'

export default {
  namespaced: true,
  state: {
    computingScr: false,
    done: false,
    loading: false,
    ready: false,
    showGreenTick: false,
    computationDate: undefined,
    data: undefined,
    globalMarketValuation: 0,
    perimeter: undefined,
    parameters: undefined,
    reports: {
      scrLoading: false,
      qrt: {
        bceColumns: true,
        beginDateQrts0901: undefined,
        loading: false,
      },
      actuarisLoading: false,
      assuretatLoading: false,
    },
    onSimulation: false,
    archiveScrExists: false,
  },
  getters: {
    computingScr: state => {
      return state.computingScr
    },
    scrDone: state => {
      return state.done
    },
    scrLoading: state => {
      return state.loading
    },
    scrReady: state => {
      return state.ready
    },
    showGreenTick: state => {
      return state.showGreenTick
    },
    qrt: state => {
      return state.reports.qrt
    },
    scrMarketResult: state => {
      return state
    },
    perimeter: state => {
      return state.perimeter
    },
    scrReportLoading: state => {
      return state.reports.scrLoading
    },
    qrtReportLoading: state => {
      return state.reports.qrt.loading
    },
    actuarisReportLoading: state => {
      return state.reports.actuarisLoading
    },
    assuretatReportLoading: state => {
      return state.reports.assuretatLoading
    },
    onSimulation: state => {
      return state.onSimulation
    },
    archiveScrExists: state => {
      return state.archiveScrExists
    },
  },
  mutations: {
    RESET(state) {
      state.computingScr = false
      state.done = false
      state.loading = false
      state.ready = false
      state.showGreenTick = false
      state.computationDate = undefined
      state.data = undefined
      state.globalMarketValuation = 0
      state.perimeter = undefined
      state.parameters = undefined
      state.reports = {
        scrLoading: false,
        qrt: {
          bceColumns: true,
          beginDateQrts0901: undefined,
          loading: false,
        },
        actuarisLoading: false,
        assuretatLoading: false,
      }
      state.onSimulation = false
      state.archiveScrExists = false
    },
    SET_SHOWGREENTICK(state, payload) {
      state.showGreenTick = payload
    },
    SET_ARCHIVESCREXISTS(state, payload) {
      state.archiveScrExists = payload
    },
    SET_SCRMARKETRESULT(state, payload) {
      if (payload === undefined) {
        state.data = undefined
        state.onSimulation = false
        state.globalMarketValuation = undefined
        state.computationDate = undefined
        state.perimeter = undefined
        state.parameters = undefined
        state.done = false
        state.loading = false
        this.commit('solvency/results/SET_SHOWGREENTICK', false, { root: true })
      } else {
        state.data = payload.globalSCRMarketResult
        state.onSimulation = payload.simulationID !== null
        state.globalMarketValuation = payload.globalMarketValuation
        var d = new Date()
        state.computationDate = d.toLocaleString()
        state.perimeter = this.getters['perimeter/perimeter']
        state.perimeter.perimeterID = payload.perimeter.perimeterID
        // state.parameters = JSON.parse(JSON.stringify(this.getters['solvency/parameters/parameters']))
        state.done = true
        state.loading = false
        this.commit('solvency/results/SET_SHOWGREENTICK', true, { root: true })
      }
    },
    SET_SCRDONE(state, payload) {
      state.done = payload
    },
    SET_SCRREADY(state, payload) {
      state.ready = payload
    },
    SET_SCRLOADING(state, payload) {
      state.loading = payload
    },
    SET_SCRREPORTLOADING(state, payload) {
      state.reports.scrLoading = payload
    },
    SET_QRTREPORTLOADING(state, payload) {
      state.reports.qrt.loading = payload
    },
    SET_ACTUARISREPORTLOADING(state, payload) {
      state.reports.actuarisLoading = payload
    },
    SET_ASSURETATREPORTLOADING(state, payload) {
      state.reports.assuretatLoading = payload
    },
    SET_DEFAULT_QRTDATE(state) {
      var d = new Date(state.perimeter.valuationDate.value)
      var q = new Date()
      q.setDate(d.getDate() - 1)
      state.reports.qrt.beginDateQrts0901 = q.toISOString().slice(0, 10)
    },
    SET_COMPUTINGSCR(state, payload) {
      state.computingScr = payload
    },
  },
  actions: {
    generate_scr_report: function ({ commit }) {
      commit('solvency/results/SET_SCRREPORTLOADING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/reporting/scr-excel',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          responseType: 'blob',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_REPORTS_SCR_ERROR', false, { root: true })
            commit('solvency/results/SET_SCRREPORTLOADING', false, { root: true })
            const filename = response.headers['content-disposition']
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim()
            FileSaver.saveAs(new Blob([response.data]), filename)
          })
          .catch(() => {
            commit('solvency/results/SET_SCRREPORTLOADING', false, { root: true })
            commit('errors/SET_REPORTS_SCR_ERROR', true, { root: true })
          })
      })
    },
    generate_qrt_report: function ({ commit }) {
      commit('solvency/results/SET_QRTREPORTLOADING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/reporting/qrt',
          method: 'GET',
          responseType: 'blob',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
            bceColumns: this.state.solvency.results.reports.qrt.bceColumns,
            beginDateQrts0901: this.state.solvency.results.reports.qrt.beginDateQrts0901,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_REPORTS_QRT_ERROR', false, { root: true })
            commit('solvency/results/SET_QRTREPORTLOADING', false, { root: true })
            const filename = response.headers['content-disposition']
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim()
            FileSaver.saveAs(new Blob([response.data]), filename)
          })
          .catch(() => {
            commit('errors/SET_REPORTS_QRT_ERROR', true, { root: true })
            commit('solvency/results/SET_QRTREPORTLOADING', false, { root: true })
          })
      })
    },
    generate_actuaris_report: function ({ commit }) {
      commit('solvency/results/SET_ACTUARISREPORTLOADING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/reporting/actuaris',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          responseType: 'blob',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_REPORTS_ACTUARIS_ERROR', false, { root: true })
            commit('solvency/results/SET_ACTUARISREPORTLOADING', false, { root: true })
            const filename = response.headers['content-disposition']
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim()
            FileSaver.saveAs(new Blob([response.data]), filename)
          })
          .catch(() => {
            commit('errors/SET_REPORTS_ACTUARIS_ERROR', true, { root: true })
            commit('solvency/results/SET_ACTUARISREPORTLOADING', false, { root: true })
          })
      })
    },
    generate_assuretat_report: function ({ commit }) {
      commit('solvency/results/SET_ASSURETATREPORTLOADING', true, { root: true })
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/reporting/assuretat',
          method: 'GET',
          params: {
            userID: Vue.prototype.$AuthService.getAccount().accountIdentifier,
          },
          responseType: 'blob',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('errors/SET_REPORTS_ASSURETAT_ERROR', false, { root: true })
            commit('solvency/results/SET_ASSURETATREPORTLOADING', false, { root: true })
            const filename = response.headers['content-disposition']
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim()
            FileSaver.saveAs(new Blob([response.data]), filename)
          })
          .catch(() => {
            commit('errors/SET_REPORTS_ASSURETAT_ERROR', true, { root: true })
            commit('solvency/results/SET_ASSURETATREPORTLOADING', false, { root: true })
          })
      })
    },
  },
}
