<template>
  <!-- <router-view
    v-if="appReady"
    v-slot="{ Component, route }"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <component
        :is="Component"
        :key="route.path"
      />
    </transition>
  </router-view> -->
  <transition v-if="appReady" name="fade" mode="out-in">
    <router-view />
  </transition>
  <loading-page v-else />
</template>

<script>
export default {
  name: 'App',
  components: {
    LoadingPage: () => import('./layouts/page/LoadingPage.vue'),
  },
  data() {
    return {
      appReady: false,
    }
  },
  mounted: function () {
    // this.createAnalyticsScripts()
    this.appReady = false
    this.$store.commit('SET_USER_ACCOUNT')
    this.$store.dispatch('get_admin').then(response => {
      this.$store.dispatch('perimeter/load_perimeter').then(() => {
        this.appReady = true
      })
    })
    if (process.env.VUE_APP_MODE === 'prod') {
      (function (c, l, a, r, i, t, y) { c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i; y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y) })(window, document, 'clarity', 'script', '8ue791yd2m')
    }
  },
}
</script>
<style>
.v-application {
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s ease;
}
</style>
