import Axios from 'axios'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    archives: [],
    categories: [],
    loading: false,
    creating: false,
    created: false,
    updating: false,
    deleting: false,
    createDialogOpened: false,
    selectedArchiveScr: undefined,
    selectedArchiveEsg: undefined,
    selectedArchiveRisk: undefined,
    selectedArchiveTranspa: undefined,
  },
  getters: {
    createDialogOpened: state => {
      return state.createDialogOpened
    },
    archives: state => {
      return state.archives
    },
    archivesScr: state => {
      return state.archives.filter(e => e.scr)
    },
    archivesEsg: state => {
      return state.archives.filter(e => e.esg)
    },
    archivesRisk: state => {
      return state.archives.filter(e => e.riskLiquidity)
    },
    created: state => {
      return state.created
    },
    creating: state => {
      return state.creating
    },
    updating: state => {
      return state.updating
    },
    deleting: state => {
      return state.deleting
    },
    categories: state => {
      return state.categories
    },
    mostRecentArchive: state => {
      var sorted = state.archives.sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0]
    },
    mostRecentArchiveScr: (state, getters) => {
      var sorted = getters.archivesScr.sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0]
    },
    mostRecentArchiveEsg: (state, getters) => {
      var sorted = getters.archivesEsg.sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0]
    },
    mostRecentArchiveRisk: (state, getters) => {
      var sorted = getters.archivesRisk.sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0]
    },
    selectedArchiveScr: state => {
      return state.selectedArchiveScr
    },
    selectedArchiveEsg: state => {
      return state.selectedArchiveEsg
    },
    selectedArchiveRisk: state => {
      return state.selectedArchiveRisk
    },
    selectedArchiveTranspa: state => {
      return state.selectedArchiveTranspa
    },
    defaultCategory: state => {
      return state.archives.findIndex(e => e.category === 'Officiel') !== -1 ? 'Officiel' : state.archives[0].category
    },
    defaultCategoryScr: (state, getters) => {
      return getters.archivesScr.findIndex(e => e.category === 'Officiel') !== -1 ? 'Officiel' : getters.archivesScr[0].category
    },
    defaultCategoryEsg: (state, getters) => {
      return getters.archivesEsg.findIndex(e => e.category === 'Officiel') !== -1 ? 'Officiel' : getters.archivesEsg[0].category
    },
    defaultCategoryRisk: (state, getters) => {
      return getters.archivesRisk.findIndex(e => e.category === 'Officiel') !== -1 ? 'Officiel' : getters.archivesRisk[0].category
    },
    defaultPortfolioHistory: (state, getters) => {
      var sorted = state.archives.filter(e => e.category === getters.defaultCategory).sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0].portfolioNames
    },
    defaultPortfolioHistoryScr: (state, getters) => {
      var sorted = getters.archivesScr.filter(e => e.category === getters.defaultCategoryScr).sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0].portfolioNames
    },
    defaultPortfolioHistoryEsg: (state, getters) => {
      var sorted = getters.archivesEsg.filter(e => e.category === getters.defaultCategoryEsg).sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0].portfolioNames
    },
    defaultPortfolioHistoryRisk: (state, getters) => {
      var sorted = getters.archivesRisk.filter(e => e.category === getters.defaultCategoryRisk).sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
      return sorted[0].portfolioNames
    },
    noArchive: state => {
      return state.archives.length === 0 || state.archives === undefined
    },
    noArchiveScr: (state, getters) => {
      return getters.archivesScr.length === 0 || getters.archivesScr === undefined
    },
    noArchiveEsg: (state, getters) => {
      return getters.archivesEsg.length === 0 || getters.archivesEsg === undefined
    },
    noArchiveRisk: (state, getters) => {
      return getters.archivesRisk.length === 0 || getters.archivesRisk === undefined
    },
    users: state => {
      var users = []
      for (var i = 0; i < state.archives.length; i++) {
        if (users.findIndex(e => e === state.archives[i].displayName) === -1) {
          users.push(state.archives[i].displayName)
        }
      }
      return users
    },
  },
  mutations: {
    RESET (state) {
      state.archives = []
      state.categories = []
      state.loading = false
      state.creating = false
      state.created = false
      state.updating = false
      state.deleting = false
      state.createDialogOpened = false
      state.selectedArchiveScr = undefined
      state.selectedArchiveEsg = undefined
      state.selectedArchiveRisk = undefined
      state.selectedArchiveTranspa = undefined
    },
    SET_LOADING: function (state, payload) {
      state.loading = payload
    },
    SET_CREATING: function (state, payload) {
      state.creating = payload
    },
    SET_CREATED: function (state, payload) {
      state.created = payload
    },
    SET_UPDATING: function (state, payload) {
      state.updating = payload
    },
    SET_DELETING: function (state, payload) {
      state.updating = payload
    },
    SET_ARCHIVES: function (state, payload) {
      state.archives = payload.sort(function (a, b) {
        var dateA = new Date(a.simulationDate)
        var dateB = new Date(b.simulationDate)
        return dateB - dateA
      })
    },
    SET_CREATEDIALOGOPENED: function (state, payload) {
      state.createDialogOpened = payload
    },
    SET_CATEGORIES: function (state, payload) {
      state.categories = payload
      if (state.categories.findIndex(e => e === 'Officiel') === -1) {
        state.categories.push('Officiel')
      }
      if (state.categories.findIndex(e => e === 'Test') === -1) {
        state.categories.push('Test')
      }
    },
    // SET_PBIS: function (state) {
    //   state.detailsPbi = {
    //     groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
    //     reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESDETAILS_REPORTID,
    //   }
    //   state.historicalPbi = {
    //     groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
    //     reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESHISTO_REPORTID,
    //   }
    //   state.comparisonPbi = {
    //     groupId: process.env.VUE_APP_POWERBI_TRANSPA_GROUPID,
    //     reportId: process.env.VUE_APP_POWERBI_SCRARCHIVESCOMPA_REPORTID,
    //   }
    // },
    RESET_SELECTEDARCHIVES (state) {
      state.selectedArchiveScr = undefined
      state.selectedArchiveEsg = undefined
      state.selectedArchiveRisk = undefined
      state.selectedArchiveTranspa = undefined
    },
    SET_SELECTEDARCHIVESCR: function (state, payload) {
      state.selectedArchiveScr = state.archives.filter(e => e.perimeterID === parseInt(payload))[0].name
    },
    SET_SELECTEDARCHIVEESG: function (state, payload) {
      state.selectedArchiveEsg = state.archives.filter(e => e.perimeterID === parseInt(payload))[0].name
    },
    SET_SELECTEDARCHIVERISK: function (state, payload) {
      state.selectedArchiveRisk = state.archives.filter(e => e.perimeterID === parseInt(payload))[0].name
    },
    SET_SELECTEDARCHIVETRANSPA: function (state, payload) {
      state.selectedArchiveTranspa = state.archives.filter(e => e.perimeterID === parseInt(payload))[0].name
    },
  },
  actions: {
    currentResultHasArchive: function (perimeterID) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/archive/all',
          method: 'GET',
          params: {
            rolename: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            return response.data.findIndex(e => e.perimeterID === perimeterID) !== -1
          })
          .catch(() => {
            return false
          })
      })
    },
    getArchives: function ({ commit, dispatch }) {
      commit('archives/SET_LOADING', true, { root: true })
      if (!this.state.perimeter.client.value) {
        commit('archives/SET_ARCHIVES', [], { root: true })
        commit('archives/SET_LOADING', false, { root: true })
        return
      }
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/archive/all',
          method: 'GET',
          params: {
            rolename: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('archives/SET_ARCHIVES', response.data, { root: true })
            commit('archives/SET_LOADING', false, { root: true })
            commit('errors/SET_LOADINGARCHIVES_ERROR', false, { root: true })
            dispatch('archives/getCategories', undefined, { root: true })
          })
          .catch(() => {
            commit('archives/SET_LOADING', false, { root: true })
            commit('errors/SET_LOADINGARCHIVES_ERROR', true, { root: true })
          })
      })
    },
    getCategories: function ({ commit }) {
      Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        Axios({
          url: this.state.baseUrl + '/api/archive/categories',
          method: 'GET',
          params: {
            rolename: this.state.perimeter.client.value.code,
          },
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('archives/SET_CATEGORIES', response.data, { root: true })
            commit('errors/SET_LOADINGARCHIVESCATEGORIES_ERROR', false, { root: true })
          })
          .catch(() => {
            commit('errors/SET_LOADINGARCHIVESCATEGORIES_ERROR', true, { root: true })
          })
      })
    },
    updateArchive: function ({ commit, dispatch }, data) {
      commit('archives/SET_UPDATING', true, { root: true })
      var url = this.state.baseUrl + '/api/archive'
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.put(url,
          {
            perimeterID: data.perimeterId,
            name: data.name,
            category: data.category,
            simulation: data.simulation,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
          ).then(() => {
            dispatch('archives/getArchives', undefined, { root: true }).then(() => {
              commit('archives/SET_UPDATING', false, { root: true })
              commit('errors/SET_UPDATINGARCHIVE_ERROR', false, { root: true })
            })
          })
          .catch(() => {
            commit('archives/SET_UPDATING', false, { root: true })
            commit('errors/SET_UPDATINGARCHIVE_ERROR', true, { root: true })
          })
      })
    },
    deleteArchive: function ({ commit, dispatch }, perimeterId) {
      commit('archives/SET_DELETING', true, { root: true })
      var url = this.state.baseUrl + '/api/archive'
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.delete(url,
          {
            params: {
              perimeterID: perimeterId,
            },
            headers: {
              authorization: config.headers.authorization,
            },
          },
          ).then(() => {
            dispatch('archives/getArchives', undefined, { root: true }).then(() => {
              commit('archives/SET_DELETING', false, { root: true })
              commit('errors/SET_DELETINGARCHIVE_ERROR', false, { root: true })
            })
          })
          .catch(() => {
            commit('archives/SET_DELETING', false, { root: true })
            commit('errors/SET_DELETINGARCHIVE_ERROR', true, { root: true })
          })
      })
    },
    createArchive: function ({ commit, dispatch }, data) {
      commit('archives/SET_CREATING', true, { root: true })
      commit('archives/SET_CREATED', false, { root: true })
      var perimeterId = null
      var simulation = false
      if (data.scr) {
        perimeterId = this.state.solvency.results.perimeter.perimeterID
        simulation = this.state.solvency.results.onSimulation
      } else if (data.esg) {
        perimeterId = this.state.esg.results.results.perimeter.perimeterID
        simulation = this.state.esg.results.onSimulation
      } else if (data.risk) {
        perimeterId = this.state.risk.results.perimeter.perimeterID
        simulation = this.state.risk.results.onSimulation
      } else {
        if (this.state.simulation.simulationMode) {
          perimeterId = this.state.simulation.perimeterId
          simulation = true
        } else {
          perimeterId = this.state.transparisation.perimeterId
          simulation = false
        }
      }
      // var perimeterId = data.scr ? this.state.solvency.results.perimeter.perimeterID : this.state.esg.results.perimeter.perimeterID
      // var simulation = data.scr ? this.state.solvency.results.onSimulation : this.state.esg.results.onSimulation
      var url = this.state.baseUrl + '/api/archive'
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios.post(url,
          {
            perimeterID: perimeterId,
            name: data.name,
            category: data.category,
            simulation: simulation,
            scr: data.scr,
            esg: data.esg,
            riskLiquidity: data.risk,
          },
          {
            headers: {
              authorization: config.headers.authorization,
            },
          },
          ).then((response) => {
            dispatch('archives/getArchives', null, { root: true })
            commit('archives/SET_CREATING', false, { root: true })
            commit('archives/SET_CREATED', true, { root: true })
            commit('archives/SET_CREATEDIALOGOPENED', false, { root: true })
            commit('errors/SET_CREATINGARCHIVE_ERROR', false, { root: true })
            if (data.scr) {
              commit('solvency/results/SET_ARCHIVESCREXISTS', true, { root: true })
            }
            if (data.esg) {
              commit('esg/results/SET_ARCHIVEESGEXISTS', true, { root: true })
            }
            if (data.risk) {
              commit('risk/results/SET_ARCHIVERISKEXISTS', true, { root: true })
            }
          })
          .catch(() => {
            commit('archives/SET_CREATING', false, { root: true })
            commit('archives/SET_CREATEDIALOGOPENED', false, { root: true })
            commit('errors/SET_CREATINGARCHIVE_ERROR', true, { root: true })
          })
      })
    },
  },
}
