import Axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    scenarios: [],
    scenariosDetailed: [],
    parametersChanged: false,
    parametersChangedUnsaved: false,
  },
  getters: {
    scenarios: state => {
      return state.scenarios
    },
    scenariosDetailed: state => {
      return state.scenariosDetailed
    },
    parametersChanged: state => {
      return state.parametersChanged
    },
    parametersChangedUnsaved: state => {
      return state.parametersChangedUnsaved
    },
  },
  mutations: {
    RESET (state) {
      state.scenarios = []
      state.scenariosDetailed = []
      state.parametersChanged = false
      state.parametersChangedUnsaved = false
    },
    SET_SCENARIOS (state, payload) {
      state.scenarios = payload
      state.scenariosDetailed = []
      payload.forEach(element => {
        if (state.scenariosDetailed.findIndex(e => e.code === element.code) === -1) {
          state.scenariosDetailed.push({ code: element.code, name: element.name, comment: element.comment, details: [] })
        }
      })
      payload.forEach(element => {
        var ix = state.scenariosDetailed.findIndex(e => e.code === element.code)
        state.scenariosDetailed[ix].details.push({
          liquidityClass: element.liquidityClass,
          bondRatio: element.bondRatio,
          equityDailyTreshold: element.equityDailyTreshold,
          scopeCode: element.scopeCode,
          spreadBidAskRatio: element.spreadBidAskRatio,
        })
      })
    },
    SET_PARAMETERSCHANGED (state, payload) {
      state.parametersChanged = payload
    },
  },
  actions: {
    getParameters: function ({ dispatch }) {
      dispatch('risk/parameters/getScenarios', undefined, { root: true })
    },
    getScenarios: function ({ commit }) {
      return Vue.prototype.$AuthService.getAxiosTokenConfig().then((config) => {
        return Axios({
          url: this.state.baseUrl + '/api/risk_liquidity/scenarios',
          method: 'GET',
          headers: {
            authorization: config.headers.authorization,
          },
        })
          .then((response) => {
            commit('risk/parameters/SET_SCENARIOS', response.data, { root: true })
            commit('errors/SET_GETSCENARIOS_ERROR', false, { root: true })
          })
          .catch(() => {
            commit('risk/parameters/SET_SCENARIOS', [], { root: true })
            commit('errors/SET_GETSCENARIOS_ERROR', true, { root: true })
          })
      })
    },
  },
}
