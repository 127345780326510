import * as Msal from 'msal'
import config from '../../b2c.config'
import Axios from 'axios'
import store from '../store'

export default class AuthService {
  constructor() {
    this.applicationConfig = {
      clientID: config.clientid,
      authority: config.signin,
      scopes: config.b2cScopes,
      reset: config.reset,
    }

    const msalConfig = {
      auth: {
        clientId: this.applicationConfig.clientID,
        authority: this.applicationConfig.authority,
        validateAuthority: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    }
    this.app = new Msal.UserAgentApplication(msalConfig)

    this.app.handleRedirectCallback((error, response) => {
      store.commit('SET_RESETLOGIN', false)
      if (error) {
        const message = error.message

        if (message && message.startsWith('AADB2C90118')) {
          store.commit('SET_RESETLOGIN', true)
          this.app.authority = this.applicationConfig.reset
          this.app.loginRedirect()
        } else if (message && message.startsWith('Login_In_Progress')) {
          this.app.acquireTokenRedirect({
            authority: this.applicationConfig.authority,
            scopes: this.applicationConfig.scopes,
          }).catch()
        } else if (message && message.startsWith('AcquireToken_In_Progress')) {
          this.app.acquireTokenRedirect({
            authority: this.applicationConfig.authority,
            scopes: this.applicationConfig.scopes,
          }).catch()
        } else if (message && message.startsWith('AADB2C90091')) {
          this.app.authority = this.applicationConfig.authority
          this.app.loginRedirect()
        } else if (message && message.includes('AADB2C90077')) {
          this.app.acquireTokenRedirect({
            authority: this.applicationConfig.authority,
            scopes: this.applicationConfig.scopes,
          }).catch()
        } else {
          this.app.loginRedirect()
        }
      }
    },
    )
  }

  login() {
    this.app.authority = this.applicationConfig.authority
    const loginRequest = {
      scopes: this.applicationConfig.scopes,
    }
    this.app.loginRedirect(loginRequest)
  }

  logout() {
    this.app.clearCache()
    store.commit('RESET', undefined, { root: true })
    this.app.logout()
  }

  getToken(tokenRequest) {
    return this.app.acquireTokenSilent(tokenRequest).catch(() => {
      // fallback to interaction when silent call fails
      return this.app
        .acquireTokenRedirect(tokenRequest)
        .then(function () { })
        .catch(() => {
          this.logout()
        })
    })
  }

  getAxiosTokenConfig() {
    const tokenRequest = {
      scopes: this.applicationConfig.scopes,
    }

    return this.getToken(tokenRequest).then((tokenResponse) => {
      return {
        headers: {
          authorization: 'Bearer ' + tokenResponse.accessToken,
        },
      }
    }).catch(error => {
      if (error.message && error.message.includes('AADB2C90077')) {
        this.app.acquireTokenRedirect({
          authority: this.applicationConfig.authority,
          scopes: this.applicationConfig.scopes,
        }).catch()
      }
    })
  }

  getAccount() {
    return this.app.account
  }

  isAuthenticated() {
    return typeof (this.app.account) !== 'undefined'
  }

  isSolvencyMember() {
    store.commit('SET_BASEURL', null, { root: true })
    return this.getAxiosTokenConfig().then((config) => {
      return Axios({
        url: store.state.baseUrl + '/api/arago/solvency-member',
        method: 'GET',
        params: {
          userID: this.getAccount().accountIdentifier,
        },
        headers: {
          authorization: config.headers.authorization,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return false
        })
    })
  }

  isTransparencyMember() {
    store.commit('SET_BASEURL', null, { root: true })
    const r = store.state.baseUrl;
    return this.getAxiosTokenConfig().then((config) => {
      return Axios({
        url: store.state.baseUrl + '/api/arago/transparency-member',
        method: 'GET',
        params: {
          userID: this.getAccount().accountIdentifier,
        },
        headers: {
          authorization: config.headers.authorization,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return false
        })
    })
  }

  isSimulationMember() {
    store.commit('SET_BASEURL', null, { root: true })
    return this.getAxiosTokenConfig().then((config) => {
      return Axios({
        url: store.state.baseUrl + '/api/arago/simulation-member',
        method: 'GET',
        params: {
          userID: this.getAccount().accountIdentifier,
        },
        headers: {
          authorization: config.headers.authorization,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return false
        })
    })
  }

  isEsgMember() {
    store.commit('SET_BASEURL', null, { root: true })
    return this.getAxiosTokenConfig().then((config) => {
      return Axios({
        url: store.state.baseUrl + '/api/arago/esg-member',
        method: 'GET',
        params: {
          userID: this.getAccount().accountIdentifier,
        },
        headers: {
          authorization: config.headers.authorization,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return false
        })
    })
  }

  isRiskMember() {
    store.commit('SET_BASEURL', null, { root: true })
    return this.getAxiosTokenConfig().then((config) => {
      return Axios({
        url: store.state.baseUrl + '/api/arago/risk-member',
        method: 'GET',
        params: {
          userID: this.getAccount().accountIdentifier,
        },
        headers: {
          authorization: config.headers.authorization,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return false
        })
    })
  }
}
